
import { Button, Col, Container, Row, Form, Modal } from "react-bootstrap";
const Errorpopup = ({ showErr, handleCloseErr,errmessage1,errmessage2,resend_btn  }) => {
    console.log("====showErr",showErr)
    return (
        <>
         <Modal className="success-box" show={showErr} onHide={handleCloseErr} centered>
                <Modal.Header>
                    <Modal.Title>
                        {/* <img src={require("../../assets/images/chemark.png")} alt="img" /> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{errmessage1}</h5>
                    <p>{errmessage2}</p>
                </Modal.Body>
                <Modal.Footer>
                  {resend_btn?
                  <Button variant="primary" onClick={handleCloseErr}>
                  Resend Email
              </Button>:

                    <Button variant="primary" onClick={handleCloseErr}>
                        Ok
                    </Button>}
                </Modal.Footer>
            </Modal>
        </>
            );
};
export default Errorpopup; 