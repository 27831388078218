// import { Audio } from  'react-loader-spinner'

// function showHideLoader() {
 
//  return (<Audio
//  height = "80"
//  width = "80"
//  radius = "9"
//  color = 'green'
//  ariaLabel = 'three-dots-loading'     
//  wrapperStyle
//  wrapperClass
//  visible={true}
// />)   

// }

// export default showHideLoader;

import { LineWave } from 'react-loader-spinner';

export const Loading = () => (
  <div className="main-login-loader">
  <div className="login-loader">
    <LineWave width="100" strokeColor="#111" />
  </div>
  </div>
);

