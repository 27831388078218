import { useState } from "react"
import { context } from "./GlobalContext"


const Context = (props) => {
    const [token, setToken] = useState("");
    const [name, setName] = useState("");
    const [showPopupModal, setShowPopupModal]=useState(localStorage.getItem("shPop") === "true" ? true : false)
    const [scrollToPricing, setScrollToPricing]=useState(localStorage.getItem("scPrice") === "true" ? true : false)
    const [planValidityExpired, setPlanValidityExpired]=useState(localStorage.getItem("planExpired"))
    const [currentPlan, setCurrentPlan] = useState();
    const [allSubPlans, setAllSubPlans] = useState();
    const [signUpModal, setSignUpModal] = useState(false);
    const [termsAndPrivacyModal, setTermsAndPrivacyModal] = useState(false);
    const [triggerLogin, setTriggerLogin] = useState(false)
    const [loginVia, setLoginVia] = useState("");
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [cancerClicked, setCancerClicked] = useState(false);
    const [activeLink,setActiveLink] = useState('dashboard');
    const [activeTab, setActiveTab] = useState(window.location.href.includes('247-virtual-assistant') ? "advisor" : window.location.href.includes('clinical-insights') ? "report" : window.location.href.includes('specialist') ? "cancer" :"");
    
    var data={
        showPopupModal,setShowPopupModal,
        scrollToPricing, setScrollToPricing, 
        planValidityExpired, setPlanValidityExpired, 
        currentPlan, setCurrentPlan, 
        allSubPlans, setAllSubPlans, 
        signUpModal, setSignUpModal,
        termsAndPrivacyModal, setTermsAndPrivacyModal,
        triggerLogin, setTriggerLogin,
        loginVia, setLoginVia,
        token, setToken,
        name,setName,
        showContactUsModal, setShowContactUsModal,
        cancerClicked,setCancerClicked,
        activeLink, setActiveLink,
        activeTab, setActiveTab
    }
    return (
        <context.Provider value={data}>
            {props.children}
        </context.Provider>
    );
}
export default Context;