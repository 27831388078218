import { useEffect, useRef, useState, useContext } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
} from "mdb-react-ui-kit";
import { openAiService } from "../../services/openai.services";
import { context } from "../../GlobalContext/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Col, Container } from "react-bootstrap";
import { Loading } from "../common/loader";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { allBloodGroup, baseUrl } from "../../config/config";
export default function ProfilePage() {
  const ct = require("countries-and-timezones");
  const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const COUNTRY_CODE = timezone.countries[0];
  const [profileData, setprofileData] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const globalData = useContext(context);
  const globalDataRef = useRef();
  const [isLoader, setIsLoader] = useState(false);

  globalDataRef.current = globalData;

  const navigate = useNavigate();
  
  useEffect(() => {
    getProfiles();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getProfiles() {
    
    openAiService
      .getProfiles()
      .then((res) => {
        console.log("name>>>>>>", res.data);
        setprofileData(res.data?.data);
        setDateOfBirth(res.data?.data?.dob);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }

  const backToDashboard = () => {
    // var navigate = localStorage.getItem("navigate") ? 
    navigate(localStorage.getItem("navigate") ? localStorage.getItem("navigate") : -1);
  };

  const calculateAge = (dateOfBirth) => {
    const birthDate = moment(dateOfBirth);
    const currentDate = moment();
    const diff = moment.duration(currentDate.diff(birthDate));
    const years = diff.years();
    const months = diff.months();
    const days = diff.days();

    var year = Math.floor(years + months / 12 + days / 365);
    // return { years, months, days };
    return year;
  };

  const age = calculateAge(dateOfBirth);

  const updateprofile = () => {
    window.location.href = "/create_profile";
  };
  return (
    <>
      <section
        style={{ backgroundColor: "#eee" }}
        className="pricing-area profiel-details-section"
      >

        <Container>
          <div className="back-btn-box">
            <a onClick={backToDashboard}>
              {" "}
              <i className="fa fa-angle-double-left me-2" aria-hidden="true"></i>
              Back{" "}
            </a>
          </div>
        </Container>
        <MDBContainer className="">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-12">
              <div className=" profile-card">
                <div className="text-center">
                  <img
                    src={
                      profileData.profile_image
                        ? baseUrl+profileData.profile_image: profileData.picture ?profileData.picture
                        : require("../../assests/images/user-placeholder.png")
                    }
                    alt="img"
                    className="rounded-circle profile-img"
                    style={{ width: "150px" }}
                  />
                  <p className=" mb-1 mt-3">{profileData.name}</p>
                  <p className=" mb-2">
                    <i className="fa fa-envelope me-2" aria-hidden="true"></i>
                    {profileData.email}
                  </p>
                  {/* <p className=" mb-4">
                    <i className="fa fa-phone me-2" aria-hidden="true"></i>
                    {profileData.phone ? profileData.phone : "N/A"}
                  </p> */}

                  <div className="d-flex justify-content-center mb-2">
                    <button
                      className="update-btn"
                      onClick={() => updateprofile()}
                    >
                      Update Profile
                    </button>
                    {/* <MDBBtn outline className="ms-1">Message</MDBBtn> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="profile-card-inner">
                <h5 className="user-text mb-3">
                  Profile Information{" "}
                  <img
                    className=""
                    src={require("../../assests/images/info.gif")}
                    alt="img"
                  />
                </h5>
                <div className="row">
                  <div className="col-sm-4 col-6">
                    {/* <MDBCardText>DOB</MDBCardText> */}
                    <div className="data-text">
                      <p>Age:</p>
                      {/* <MDBCardText className="text-muted">{profileData.dob}</MDBCardText> */}
                      <p className="text-muted">
                        <b>
                          {!isNaN(age) && age !== null && age !== ""
                            ? age + " " + "years"
                            : "N/A"}{" "}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mt-3 col-6">
                    <div className="data-text">
                      <MDBCardText>Gender:</MDBCardText>

                      <MDBCardText className="text-muted">
                        <b>{profileData.gender ? capitalizeFirstLetter(profileData.gender) : "N/A"}</b>
                      </MDBCardText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
            </div>
          </div>

        </MDBContainer>
      </section>
      {isLoader && <Loading />}
    </>
  );
}
