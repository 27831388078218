import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";



const Sidebar = () => {
    const navigate = useNavigate();
    // const [activeLink, setActiveLink] = useState('dashboard')
    const [onMobileClick, setOnMobileClick] = useState(false);
    const globalData = useContext(context);
    const [buttonText, setButtonText] = useState(require("../../assests/images/menu-icon.svg").default);

    useEffect(() => {
      if(window.location.href.includes('my-appointment')){
        globalData.setActiveLink('my-appointment');
      }else if(window.location.href.includes('report-insight')){
        globalData.setActiveLink('report-insight');
      }else{
        globalData.setActiveLink('dashboard');
      }
    }, [])

    function ChangeText(text) {
        if (text == require("../../assests/images/menu-icon.svg").default) {
            setButtonText(require("../../assests/images/close-icon.svg").default)
        } else {
            setButtonText(require("../../assests/images/menu-icon.svg").default)
        }
    }

    return (
        <>
            <div className={`doctor-left-pannel ${window?.innerWidth < 1200 && onMobileClick
                ? "mobile-view-bookmark"
                : ""
                }`}>



                <ul>
                    <li className={globalData.activeLink == "dashboard" ? "active sidebar-li" : "sidebar-li"} onClick={() => {globalData.setActiveLink('dashboard')
                    navigate('/dashboard')
                    setOnMobileClick(!onMobileClick)
                    ChangeText(buttonText)}}>
                        <a >
                            <span className="sidebar-icon">
                                <img
                                    className=""
                                    src={require("../../assests/images/shop.svg").default}
                                    alt="img"
                                />
                            </span>
                            Dashboard</a>
                    </li>
                    <li className={globalData.activeLink == "my-appointment" ? "active sidebar-li" : "sidebar-li"} onClick={() => {globalData.setActiveLink('my-appointment')
                    navigate('/my-appointment')
                    setOnMobileClick(!onMobileClick)
                    ChangeText(buttonText)
                    }}>
                        <a >
                            <span className="sidebar-icon">
                                <img
                                    className=""
                                    src={require("../../assests/images/medical.svg").default}
                                    alt="img"
                                />
                            </span>
                            My Appointments</a>
                    </li>
                    <li className={globalData.activeLink == "report-insight" ? "active sidebar-li" : "sidebar-li"} onClick={() => {globalData.setActiveLink('report-insight')
                    navigate('/report-insight')
                    setOnMobileClick(!onMobileClick)
                    ChangeText(buttonText)}}>
                        <a >
                            <span className="sidebar-icon">
                                <img
                                    className=""
                                    src={require("../../assests/images/main-report.svg").default}
                                    alt="img"
                                />
                            </span>
                            Reports Insights</a>
                    </li>
                </ul>

            </div>

            <div className="chat-animated-button doctor-side-pannel">
                <button
                    onClick={() => {
                        setOnMobileClick(!onMobileClick)
                        ChangeText(buttonText)
                    }}
                    className="animated-btn"
                >
                    <img
                        src={buttonText}
                        alt="img"
                        className="me-1"
                    />
                </button>
            </div>
        </>

    )
}

export default Sidebar;