export function getItem(type){
    return localStorage.getItem(type)?JSON.parse(localStorage.getItem(type)):false;
}

export function setItem(type,value){
    if(typeof(value) == "object"){
        localStorage.setItem(type,JSON.stringify(value));
    }else{
        localStorage.setItem(type,value);
    }

}

export function capitalizeFirstLetter(name_str) {
    console.log("string>>>>>>>",name_str);
    return name_str?.charAt(0)?.toUpperCase() + name_str?.slice(1);
}