export const openAiApiUrl = "https://api.openai.com/v1/chat/completions"
export const openAiImageVariationUrl = "https://api.openai.com/v1/images/variations"

export const OPENAI_SECRET_API_KEY = "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"

export const OPENAI_ORGANIZATION_ID = "org-EKNzI3W6Lfpd0T7MOpXjFmU2"

export const GOOGLE_CLIENT_ID = "279502010852-620btdovam7pftovu7bi1ol91jrcbihv.apps.googleusercontent.com"

export const STRIPE_KEY = "sk_test_51NQqzFSA6chiZa9Ye2bvirms4kj765EbNvBhaLpX8kfhByuTbxj3IgY0WSjBGrrkE08TUXBDqzUkYNEcFL0gSOhv00Q2SfPeHs"

export const STRIPE_Publish_KEY = "pk_test_51NQqzFSA6chiZa9Y9vEelnF4TAalki7gKGokCXUWNOJd8pB5ZvGg7HE44dooj3l2H30UQxKUGu4k4El9V5UmW11U00N8y50ZEs" // staging

export const APP_URL = "https://staging.skylars.io/" // staging
export const CONTACT_US_EMAIL = "info@aihealthassistant.pro"

export const DEFAULT_PLAN_ID = "price_1NQr1jSA6chiZa9YyoEC8UES"
export const PREMIUM_PLAN_ID = "price_1NQr6TSA6chiZa9YbVSSZYB6"
export const stripe_prod_id = "prod_ODHaV4vDEhCTry"
export const Project_Title = "Skyler"


// export const doctorSocket = "ws://api.aihealthassistant.pro/ws/appoint/"
// export const socketUrl = "wss://api.aihealthassistant.pro/ws/doctor/appointment"

// ========================================================================================


// local urls
// export const chatSocketUrl = "ws://127.0.0.1:8000/ws/chat/";
// export const pdfSocketUrl = "ws://127.0.0.1:8000/ws/pdf/";
// export const baseUrl = "http://127.0.0.1:8000"

// server urls
export const chatSocketUrl = "ws://api.skylars.io/ws/chat/";
export const pdfSocketUrl = "wss://api.skylars.io/ws/pdf/";
export const baseUrl = "https://api.skylars.io/"



// ========================================================================================

  

export const sampleQuestions =  [
    [require("../assests/images/33.png") , "Does building insurance cover renovations or extensions?"],
    [require("../assests/images/33.png") , "Is my car covered if it's damaged in an accident I didn’t cause?"],
    [require("../assests/images/33.png") , "Is liability insurance necessary for small businesses?"],
    [require("../assests/images/33.png") , "How do I add family members to my health insurance policy?"],
    [require("../assests/images/33.png") , "What’s the difference between term life and whole life insurance?"],
    [require("../assests/images/33.png") , "How do I file a claim for a canceled flight or lost baggage?"],
    [require("../assests/images/33.png"), "How do I update my insurance policy if my circumstances change?"],
]

export const cancerQuestions =  {
    Neurology: [[require("../assests/images/11.png") , "Can you provide information about common symptoms of [Neurological Condition]?"],
    [require("../assests/images/22.png") , "What are the treatment options available for [Neurological Condition]?"],
    [require("../assests/images/33.png") , "How can I manage chronic migraines effectively?"],
    [require("../assests/images/44.png") , "Tell me about the early signs of Parkinson's disease."],
    [require("../assests/images/55.png") , "What lifestyle changes can help improve cognitive health?"],
    [require("../assests/images/55.png") , "Explain the differences between a stroke and a transient ischemic attack (TIA)."]],

    Cardiology: [[require("../assests/images/11.png") , "What are the risk factors for heart disease and how can I reduce them?"],
    [require("../assests/images/22.png") , "Explain the symptoms of a heart attack and when to seek immediate medical help."],
    [require("../assests/images/33.png") , "What is the role of cholesterol in heart health, and how can I maintain healthy levels?"],
    [require("../assests/images/44.png") , "Can you provide tips for managing hypertension and preventing complications?"],
    [require("../assests/images/55.png") , "Tell me about the benefits of regular exercise for cardiovascular health."],
    [require("../assests/images/55.png") , "How can I differentiate between angina and a heartburn episode?"]],

    Gynecology: [[require("../assests/images/11.png") , "Explain the different types of contraception options available for women."],
    [require("../assests/images/22.png") , "What are the common symptoms of PCOS and how is it diagnosed?"],
    [require("../assests/images/33.png") , "Provide tips for managing menstrual pain and discomfort."],
    [require("../assests/images/44.png") , "Tell me about the importance of regular pap smears and cervical cancer prevention."],
    [require("../assests/images/55.png") , "What are the key aspects of prenatal care during pregnancy?"],
    [require("../assests/images/55.png") , "Explain the potential causes and treatments for abnormal uterine bleeding."]
],

    Diabetes: [[require("../assests/images/11.png") , "What are the key factors in managing type 2 diabetes?"],
    [require("../assests/images/22.png") , "How does insulin work, and when is it necessary for diabetes management?"],
    [require("../assests/images/33.png") , "Provide tips for monitoring blood glucose levels effectively."],
    [require("../assests/images/44.png") , "Can you explain the importance of a balanced diet for people with diabetes?"],
    [require("../assests/images/55.png") , "Tell me about the potential complications of uncontrolled diabetes and how to prevent them."],
    [require("../assests/images/55.png") , "What are some common myths about diabetes that need to be debunked?"]],

    Pediatrics: [[require("../assests/images/11.png") , "What are the essential vaccinations recommended for infants and young children?"],
    [require("../assests/images/22.png") , "Explain the common symptoms of childhood allergies and how to manage them."],
    [require("../assests/images/33.png") , "How can I ensure my child's healthy growth and development?"],
    [require("../assests/images/44.png") , "Provide tips for dealing with common childhood illnesses like cold and flu."],
    [require("../assests/images/55.png") , "Tell me about the signs of developmental delays in children and when to seek professional evaluation."],
    [require("../assests/images/55.png") , "What are some strategies to encourage healthy eating habits in kids?"]],

    Oncology: [[require("../assests/images/11.png") , "Can you provide information about common symptoms of Oncology?"],
    [require("../assests/images/22.png") , "What are the treatment options available for Oncology and stages of Oncology?"],
    [require("../assests/images/33.png") , "Tell me about the early signs and risk factors associated with Oncology."],
    [require("../assests/images/44.png") , "What lifestyle changes can help support my recovery and overall well-being during cancer treatment?"],
    [require("../assests/images/55.png") , "Explain the differences between chemotherapy, radiation therapy, and immunotherapy as treatment options for cancer."]]

}




export const prompts = [

    ["General Insurance?", [
        "What types of insurance policies do you offer? (e.g., health, life, home, building, business insurance)?",
        "How can I choose the right insurance policy for my needs?",
        "Can you explain the key terms in my insurance policy?",
        "What is a deductible, and how does it affect my coverage?",
        "How do I know if my policy covers a specific event or risk?"
    ]],

    ["Building and Home Insurance?", [
        "What is covered under home and building insurance?",
        "Does my policy cover damage caused by weather or natural disasters?",
        "Are the contents of my home included in my building insurance policy?",
        "How do I calculate the value of my home and its contents for insurance purposes?",
        "Does building insurance cover renovations or extensions?"
    ]],

    ["Health Insurance?", [
        "What is covered under my health insurance policy?",
        "Does my policy cover pre-existing conditions?",
        "What’s the difference between in-network and out-of-network providers?",
        "Are prescription drugs covered in my health insurance plan?",
        "How do I add family members to my health insurance policy?"
    ]],

    ["Life Insurance?", [
        "What’s the difference between term life and whole life insurance?",
        "How much life insurance coverage do I need?",
        "Can I change my life insurance beneficiaries?",
        "What happens to my life insurance policy if I miss a premium payment?",
        "Is there a cash value component in my life insurance policy?"
    ]],

    ["Business Insurance?", [
        "What types of business insurance are available?",
        "How does business interruption insurance work?",
        "Is liability insurance necessary for small businesses?",
        "What is covered under commercial property insurance?",
        "Can I bundle different types of business insurance for cost savings?"
    ]],

    ["Travel Insurance:", [
        "What is covered by travel insurance?",
        "Does travel insurance cover medical emergencies abroad?",
        "How do I file a claim for a canceled flight or lost baggage?",
        "Can I get coverage for trip cancellations due to illness or emergencies?",
        "Does my travel insurance cover adventure or sports activities?"
    ]]
];
// make sure that question is at index 1 in each array
 
export const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const allBloodGroup = ["A+","A-","B+","B-","AB+","AB-","O+","O-"]
export const allDEPARTMENT = ["Neurology","Cardiology","Gynecology","Pediatrics","Oncology","Orthopedics","Others"]

// export const stagingpalID= "prod_O9p22A5oocuqre"
// export const livePlanID ="prod_Nu4Ic73wsNzWOm"
