import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
    const navigate = useNavigate();
    return(
        <>
        <div className="error-area">
            <div className="inner-content">

            <h3>Coming Soon.</h3>
            <span className="try-ti-buton" onClick={()=>navigate("/")}>Back to home</span>
        </div>
        </div>
        </>
    )
}

export default ComingSoon;