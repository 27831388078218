import React, { useRef, useState, useContext, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { allBloodGroup, baseUrl } from "../../config/config";
import { context } from "../../GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import { setItem } from "../utils/localStorage";
import { Loading } from "../common/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyProfile = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [nameerror, setNameerror] = useState("");
  const [blood, setBlood] = useState("");
  const [blooderror, setBlooderror] = useState("");
  const [heightft, setHeightft] = useState("");
  const [heightfterror, setHeightfterror] = useState("");
  const [heightinch, setHeightinch] = useState(0);
  const [heightincherror, setHeightincherror] = useState("");
  const [dob, setDob] = useState(new Date("2010-01-01"));
  const [doberror, setDoberror] = useState("");
  const [weight, setWeight] = useState("");
  const [weighterror, setWeighterror] = useState("");
  const bloodGroupRegex = /^(A|B|AB|O)[+-]$/;
  const [mobile, setMobile] = useState("");
  const [temporaryNumber,setTemporaryNumber] = useState("");
  const [mobileCode, setmobileCode] = useState("91");
  const [profileImage, setProfileImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [otp, setotp] = useState("");
  const [otpErr, setotpErr] = useState("");
  const [mobileErr, setMobileErr] = useState();
  const [verifymobile, setverifymobile] = useState(false);
  const [authToken, setauthToken] = useState(localStorage.getItem("token"));
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("user")).email
  );
  const [profileData, setprofileData] = useState("");
  const [showLoader, setshowLoader] = useState(false);
  const [gender, setGender] = useState("");
  const [genderError, setGendererror] = useState("");
  const globalData = useContext(context);
  const globalDataRef = useRef();
  const [counter, setCounter] = useState(0);
  globalDataRef.current = globalData;
  const parsedData = JSON.parse(localStorage.getItem("user")).id;

  useEffect(() => {
    getProfiles();
  }, []);
  const handleClose = () => {
    setShowModal(false);
  };
  
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  
  
  const handleshow = () => {
    setshowLoader(true);
    let params = {
      phone: mobile,
    };
    openAiService
      .send_otp(params)
      .then((res) => {
        setshowLoader(false);
        setShowModal(true);
        setCounter(60);
      })
      .catch((error) => {
        setMobileErr(error.response.data.error);
        setshowLoader(false);
      });
  };

  const resendOtp = () => {
    let params = {
      phone: mobile,
    };
    openAiService
      .send_otp(params)
      .then((res) => {
        setshowLoader(false);
        setCounter(60);
      })
      .catch((error) => {
        setshowLoader(false);
      });
  };

  const verifyOtp = () => {
    let params = {
      phone: mobile,
      otp: otp,
    };
    openAiService
      .verify_otp(params)
      .then((res) => {
        Swal.fire(
          "success",
          " Your phone number has been verified.",
          "success"
        );
        handleClose();
        setverifymobile(true);
        setotp("");
        setotpErr("");
        setCounter(60);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        setotpErr(error.response.data.error);
        setshowLoader(false);
      });
  };

  function getProfiles() {
    console.log(">>>>>>>res>>>>>>>>>>>", localStorage.getItem("token"));
    console.log("browser is running",/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
    setshowLoader(true);
    openAiService
      .getProfiles()
      .then((res) => {
        setEmail(res.data?.data.email);
        setName(res.data?.data.name);
        setGender(res.data?.data.gender);
        setImageUrl(res.data?.data?.profile_image ? baseUrl + res.data?.data?.profile_image : res.data?.data?.picture)
        setprofileData(res.data?.data);
        moment(res.data?.data?.dob).format("MM/DD/YYYY");
        setDob(moment(res.data?.data?.dob).format("YYYY-MM-DD"));
        console.log(
          ">>>>>>>heightdata>>>>>>>>>>>",
          moment(res.data?.data?.dob).format("MM/DD/YYYY")
        );
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(">>>>>>>heightdata>>>=nnnnnn====>>>>>>>>", error);
        setshowLoader(false);
      });
  }
  
  function onChangeName(e) {
    let data = e.target.value;
    console.log(e.target.value.length);
    if (e.target.value.trim() === "") {
      setNameerror("Name field is required.");
      setName("");
    } else if (e.target.value.length > 30) {
      setNameerror("Name field should have 30 characters only.");
      // setName("")
    } else {
      setNameerror("");
      setName(data);
    }
  }

  function onChangeGender(e) {
    let data = e.target.value;
    if (!data) {
      setGendererror("Gender is required.");
      setGender("");
    } else {
      setGendererror("");
      setGender(data);
    }
    // setBlood(data)
  }
  function onPhoneChanged(a, b, c, d) {
    setmobileCode("+" + b.dialCode); 
    setMobile("+" + a);
    if (a.length > 10) {
      if(temporaryNumber == "+" + a){
        setverifymobile(true);
      }else{
        setverifymobile(false);
      }
    }
  }

  function onChangeDob(date) {
    let data = date;
    const selectedDate = new Date(data);
    const currentDate = new Date("2010-01-01");
    if (!data) {
      setDoberror("DOB field is required");
      setDob("");
    } else if (isNaN(selectedDate.getTime())) {
      setDoberror("Invalid date of birth");
      setDob("");
    } else if (selectedDate > currentDate) {
      setDoberror("DOB can't be set as a future date.");
      setDob("");
    } else {
      setDoberror("");
      setDob(moment(data).format("YYYY-MM-DD"));
    }
  }


  function OnSubmit(e) {
    e.preventDefault();
    if (name) {
      setNameerror("");
    } else {
      setNameerror("Name field is required.");
    }
    const selectedDate = new Date(dob);
    const currentDate = new Date();

    if (!dob) {
      setDoberror("DOB field is required.");
    } else if (isNaN(selectedDate.getTime())) {
      setDoberror("Invalid date of birth");
    } else if (selectedDate > currentDate) {
      setDoberror("DOB can't be set as a future date.");
    } else {
      setDoberror("");
    }

    if (
      authToken &&
      name &&
      !nameerror &&
      dob &&
      !doberror
    ) {
      function userDetails() {
        setshowLoader(true);
        var formdata = new FormData();
        formdata.append("name", name);
        formdata.append("dob", dob);
        formdata.append("gender", gender);
        if (profileImage) {
          formdata.append("profile_image", profileImage)
        }
        openAiService
          .user_detail(parsedData, formdata)
          .then((res) => {
            setshowLoader(false);
            setItem("user", res.data.data);
            Swal.fire(
              "success",
              "Profile data updated successfully.",
              "success"
            ).then(() => {
              navigate("/my_profile");
            });
            console.log("res----------->", res);
          })
          .catch((error) => {
            setshowLoader(true);
            console.log("Error", error);
          });
      }
      userDetails();
    }
  }

  return (
    <>
      {/* {showLoader && (
        <div className="main-loader">
          <div className="loader3">
            <span></span>
            <span></span>
          </div>
        </div>
      )} */}

    {showLoader && <Loading />}

      <section className="pricing-area profile-main">
        <div className="container"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="profile-content-area my-profile-content">
                <h2>My Profile</h2>
                <Row >
                  <Col md={4} lg={3}>
                    <div className="upload-family-img profile-upload">
                      <img
                        className=""
                        id="member_image"
                        src={imageUrl ? imageUrl : require("../../assests/images/family-img.png")}
                        alt="img"
                      />
                      <Form.Control id="member_profile" onChange={(e) => {
                        setProfileImage(e.target.files[0])
                        setImageUrl(URL.createObjectURL(e.target.files[0]))
                      }} type="file" accept="image/*"></Form.Control>
                      <p>Upload Profile Picture here</p>
                    </div>
                  </Col>
                  <Col md={8} lg={9}>
                    <Row>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Name*</Form.Label>
                          <Form.Control
                            type="text"
                            value={name}
                            placeholder="Enter Name"
                            onChange={(e) => onChangeName(e)}
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          <p style={{ color: "red" }}>{nameerror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3 date-icon main" controlId="formBasicPassword">
                          <Form.Label>DOB*</Form.Label>
                          {/^((?!chrome|android).)*safari/i.test(navigator.userAgent) ?
                            <DatePicker
                            selected={new Date(dob)}
                            onChange={(e) => onChangeDob(e)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            placeholderText="Enter DOB"
                            maxDate={new Date("2010-01-01")}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          
                          :<Form.Control
                          value={dob}
                          type="date"
                          max="2010-01-01"
                          placeholder="Enter DOB"
                          onChange={(e) => onChangeDob(e.target.value)}
                          onKeyDown={(e) => {
                            return false;
                            // if (!e.target.value && e.code === "Space") {
                            //   e.preventDefault();
                            // }
                          }}
                        />
                      }
                               
                          {/* {doberror} */}
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                          <p style={{ color: "red" }}>{doberror}</p>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Email*</Form.Label>
                          <Form.Control
                            type="email"
                            value={email}
                            disabled
                            placeholder="Enter Email"
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Gender*</Form.Label>
                          <Form.Select
                            value={gender}
                            aria-label="Default select example"
                            onChange={(e) => onChangeGender(e)}
                          >
                            <option value={""}>Select Gender</option>
                            <option value={"male"}>Male</option>
                            <option value={"female"}>Female</option>
                            <option value={"other"}>Other</option>
                          </Form.Select>
                          <p style={{ color: "red" }}>{genderError}</p>
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>
                </Row>
                <Button
                  className="profile-submit-btn"
                  variant="unset"
                  type="button"
                  onClick={(e) => OnSubmit(e)}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => navigate('/my_profile')}
                  variant="unset"
                  type="button"
                  className="profile-submit-btn back-btn"
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>

          <Modal
            className="add-notes"
            show={showModal}
            onHide={() => {
              handleClose();
            }}
            centered
          >
            <Modal.Header closeButton className="p-0">
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 mt-3">
              <Form>
                <div className=" p-2 text-center">
                  <h6 className="mb-3">
                    Please enter the one time password(OTP) to verify your phone
                    number.
                  </h6>
                  <div>
                    {" "}
                    <span>A code has been sent to</span>{" "}
                    <small>*******{mobile.substring(mobile.length - 4)}</small>{" "}
                  </div>
                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center mt-2 verify-input-field"
                  >
                    <OtpInput
                      className=""
                      value={otp}
                      onChange={setotp}
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          className="m-2 text-center form-control rounded"
                        />
                      )}
                      inputStyle={{ width: "3em" }}
                    />
                  </div>
                  <p className="error text-danger">{otpErr}</p>
                  <div className="mt-4">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary px-4 validate"
                      onClick={verifyOtp}
                    >
                      Verify
                    </button>{" "}
                  </div>
                  {counter > 0 && (
                    <p className="mt-3">
                      Resend OTP in{" "}
                      <span className="timer">
                        <i
                          className="fa fa-clock-o position-unset"
                          aria-hidden="true"
                        ></i>
                        {"00:" + counter}
                      </span>
                    </p>
                  )}
                  {counter == 0 && (
                    <div className="mt-4">
                      {" "}
                      <button
                        type="button"
                        className={
                          counter == 0
                            ? "btn btn-warning px-4 validate"
                            : "btn btn-disabled px-4 validate"
                        }
                        onClick={resendOtp}
                      >
                        Resend OTP
                      </button>{" "}
                    </div>
                  )}
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </>
  );
};
export default MyProfile;
