import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import {
  DEFAULT_PLAN_ID,
  EMAILREGEX,
  Project_Title,
} from "../../config/config";
import { useRef } from "react";
import { openAiService } from "../../services/openai.services";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { AssetRender } from "../common/common";
// import videoFile from "../../assests/images/home-right-video.mp4"

const ct = require("countries-and-timezones");

const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];
const myStyles = {
  transform: 'scale(0.77)',
  width: '100%',
};
const Home = () => {
  const navigate = useNavigate();
  const globalData = useContext(context);
  const location = useLocation();
  const pricingSectionRef = useRef();
  const [contactUsFields, setContactUsFields] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [contactUsFieldsErrors, setContactUsFieldsErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
  });
  const [isCollapsed, setIsCollapsed] = useState({
    isCollapsedOne: false,
    isCollapsedTwo: false,
    isCollapsedThree: false,
    isCollapsedFour: false,
    isCollapsedFive: false,
    isCollapsedSix: false,
    isCollapsedSeven: false,
    isCollapsedEight: false,
  });
  const [isImportant, setIsImportant] = useState({
    isCollapsedOne: false,
    isCollapsedTwo: false,
    isCollapsedThree: false,
    isCollapsedFour: false,
    isCollapsedFive: false,
    isCollapsedSix: false,
    isCollapsedSeven: false,
    isCollapsedEight: false,
  });

  function onChange(value) {
    // isCaptchaValue.current = value;
    setIsCaptchaValue(value);
    console.log("Captcha value:", value);
  }
  // useEffect( ()=>{
  //   if(localStorage.getItem("token")){
  //     try{
  //       axios.get("https://api.ipify.org?format=json").then(async(data)=> {
  //         console.log("data---", data?.data?.ip);
  //         await axios.get('https://ipinfo.io/json', {
  //           headers: {
  //             'Accept': 'application/json',
  //           }
  //         }).then(res=>{
  //             console.log("res---", res.json())
  //             debugger
  //           })
  //       })
  //     }catch{}
  //   }
  // }, [])
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.email) {
      setContactUsFields((obj) => {
        return {
          ...obj,
          email: user.email,
        };
      });
    }
    if (globalData.scrollToPricing) {
      if (pricingSectionRef.current) {
        window.scrollTo(0, pricingSectionRef.current.offsetTop);
      }
      globalData.setScrollToPricing(false);
    }
  }, [globalData.scrollToPricing]);
  const handleContactUsSubmit = (e) => {
    e.preventDefault();
    let formValidated = true;
    if (contactUsFields.firstName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          firstName: true,
        };
      });
    }
    if (contactUsFields.lastName.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          lastName: true,
        };
      });
    }
    if (contactUsFields.phone.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    } else if (contactUsFields.phone.trim().length < 10) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (contactUsFields.email.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    } else if (!EMAILREGEX.test(contactUsFields.email)) {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          email: true,
        };
      });
    }
    if (contactUsFields.message.trim() === "") {
      formValidated = false;
      setContactUsFieldsErrors((obj) => {
        return {
          ...obj,
          message: true,
        };
      });
    }
    if (formValidated) {
      let data = {
        first_name: contactUsFields.firstName,
        last_name: contactUsFields.lastName,
        email: contactUsFields.email,
        message: contactUsFields.message,
        phone: "+" + contactUsFields.phone,
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      openAiService
        .contactUs(data)
        .then((res) => {
          console.log("contact us api response", res);
          Swal.fire(
            "Success",
            "Thank you for submitting your request. We are reviewing it and will respond soon!",
            "success"
          ).then(() => {
            globalData.setShowContactUsModal(false);
            setContactUsFields({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
              phone: "",
            });
          });
        })
        .catch((err) => {
          Swal.fire("Error", "Something went wrong.", "error");
          console.log("contact us api error", err);
        });
    }
  };
  return (
    <>
      {/* Main hero section start */}
      <section className="main-hero-section">
        <Container>
          <Row className="align-items-center">
            <Col md={5}>
              <div className="main-hero-content">
                <div className="content">
                  <p className="content__container__text">
                    Meet Skyler, your assistant to help with
                  </p>
                  <div className="content__container">

                    <ul className="content__container__list">
                      <li className="content__container__list__item">General Insurance</li>
                      <li className="content__container__list__item">Life Insurance</li>
                      <li className="content__container__list__item">Business Insurance</li>
                      <li className="content__container__list__item">Health Insurance</li>
                    </ul>
                  </div>
                  <div className="text-center mt-5">
                  <Button
                      className="try-ti-buton get-in-touch animated-btn mt-3"
                      onClick={() => 
                        localStorage.getItem("user") 
                          ? navigate("/247-virtual-assistant") 
                          : globalData.setTriggerLogin(true)
                      }
                    >
                      <span className="pulse1"></span>
                      24/7 Virtual Assistant
                    </Button>
                    {/* <Button
                      className="try-ti-buton get-in-touch animated-btn mt-3"
                      onClick={() => globalData.setShowContactUsModal(true)}
                    >
                      <span className="pulse1"></span>
                      24/7 Virtual Assistant
                    </Button> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="main-hero-img">
                {/* <img
                  src={require("../../assests/images/anime-1.svg").default}
                  alt="img"
                  className="anime-1 vert-move"
                />
                <img
                  src={require("../../assests/images/anime-2.svg").default}
                  alt="img"
                  className="anime-2 vert-move"
                /> */}
                {/* <img
                  src={require("../../assests/images/anime-3.svg").default}
                  alt="img"
                  className="anime-3"
                /> */}
                {/* <img
                  src={require("../../assests/images/anime-main.png")}
                  alt="img"
                  className="anime-main"
                /> */}
                {/* <video playsinline autoPlay muted loop>
                  <source
                    className="anime-main"
                    src={require("../../assests/images/home-right-video.mp4")}
                    type="video/mp4"
                  />
                </video> */}
                 <img
                  src={require("../../assests/images/Group 1000006915.png")}
                  alt="img"
                  className="anime-main"
                />

             {/*   <AssetRender fileUrl={videoFile} fileType="video"/> */}
               
                <div id="container-main">
                  <div id="c1" className="circle-wave"></div>
                  <div id="c2" className="circle-wave"></div>
                  <div id="c3" className="circle-wave"></div>
                  <div id="c4" className="circle-wave"></div>
                  <div id="c5" className="circle-wave"></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Main hero section end */}


      <section className="hero-banner">
        <div className="overlay-layer"></div>
        {/* <video autoPlay muted loop>
          <source
            src={require("../../assests/images/ai_demo.mp4")}
            type="video/mp4"
          />
        </video> */}

        <div className="hero-content text-light text-center mt-3">
          <h1>
           How <span>{Project_Title}</span> Helps Insurance Agents ,<br />
          </h1>
          <p>
            <br />Skyler is your go-to resource for all things insurance. From understanding policy terms and conditions to clarifying claims processes, Skyler ensures you have the information you need at your fingertips. You can trust Skyler to handle complex insurance queries and assist in navigating the intricacies of different insurance products, allowing you to focus on building relationships with your clients
          </p>
          {/* {localStorage.getItem("user") &&
            localStorage.getItem("plan") === null ? "" : localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("plan"))?.id !== DEFAULT_PLAN_ID
            ? "" : <button
              onClick={() => globalData.setShowContactUsModal(true)}
              className="try-ti-buton animated-btn get-in-contact-btn"
            >Get in Contact </button>} */}

        </div>
      </section>
      {/* {localStorage.getItem("user") &&
            localStorage.getItem("plan") === null
              ? "Get in Contact"
              : localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("plan"))?.id !== DEFAULT_PLAN_ID
              ? "Get your healthcare advice"
              : "Get in Contact"} */}
      {/* <section className="hero-banner">
        <Container fluid className="hero-outer">
          <Row className="align-items-center hero-top justify-content-center">
      
                <video autoplay muted>
              <source src={video} type="video/mp4" />
              <source src={video} type="video/ogg" />
            </video>
                
            <Col lg={12} md={12}>
              <div className="hero-content text-light text-center mt-3">
                <h1>
                  Instant Access to Your Personal <span>AI attorney</span>
                </h1>
                <p>
                  Tired of expensive consultations, long wait times for
                  appointments, and confusing and complicated legal texts? Get
                  legal help from the AI Attorney team anytime and anywhere.
                </p>
                <button
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/legal-advisory");
                    } else {
                      // globalData.setTriggerLogin(true);
                      //
                      globalData.setShowContactUsModal(true);
                    }
                  }}
                  className="try-ti-buton animated-btn"
                >
                  {localStorage.getItem("user") &&
                  localStorage.getItem("plan") === null
                    ? "Get in Contact"
                    : localStorage.getItem("user") &&
                      JSON.parse(localStorage.getItem("plan"))?.id !==
                        DEFAULT_PLAN_ID
                    ? "Get your legal advice"
                    : "Get in Contact"}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      

      <section className="call-to-action top-call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div className="call-to-action-left">
                <h4>Get Started with Skyler Today!</h4>
                <p>Skyler is designed to make your insurance business more efficient and productive. Start a conversation now and discover how Skyler can transform your workflow!</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="call-to-action-right">
                {/* <Button
                  className="try-ti-buton get-in-touch animated-btn"
                  onClick={() => globalData.setShowContactUsModal(true)}
                >
                  Get in Touch
                </Button> */}
                <Button
                  className="try-ti-buton get-in-touch animated-btn mt-3"
                  onClick={() => 
                    localStorage.getItem("user") 
                      ? navigate("/247-virtual-assistant") 
                      : globalData.setTriggerLogin(true)
                  }
                >
                  <span className="pulse1"></span>
                  Access Virtual Assistant
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="advantage">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="NFTICALLYAdvantageContent">
                <h2 className="text-light">
                Welcome to Skyler – Your Trusted Insurance AI Assistant
                </h2>
                <p>
                In the fast-paced world of insurance, staying ahead of client needs while managing policies, claims, and complex queries can be overwhelming. That’s where Skyler steps in. Skyler is an AI-powered chatbot designed specifically to assist insurance agents, making your job easier and more efficient.
                With Skyler, you get:
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="capabilities-section">
                <div id="accordionExample" className="accordion shadow">
                  {/* <!-- Accordion item 1 --> */}
                  <div className="card">
                    <div
                      id="headingOne"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          className={isImportant.isCollapsedOne ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsImportant({
                              ...isImportant,
                              isCollapsedOne: !isImportant.isCollapsedOne,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                            })
                          }
                        >
                          1. Instant Answers
                        </button>
                      </h2>
                    </div>
                    {isImportant.isCollapsedOne ? (
                      <div
                        id="collapseOne"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Get quick and accurate responses to your insurance-related questions, from policy details to claim procedures.

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 2 --> */}
                  <div className="card">
                    <div
                      id="headingTwo"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          className={isImportant.isCollapsedTwo ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsImportant({
                              ...isImportant,
                              isCollapsedOne: false,
                              isCollapsedTwo: !isImportant.isCollapsedTwo,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                            })
                          }
                        >
                          2. Seamless Support
                        </button>
                      </h2>
                    </div>
                    {isImportant.isCollapsedTwo ? (
                      <div
                        id="collapseTwo"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Skyler works 24/7, providing non-stop assistance for all your queries, even after business hours.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 3 --> */}
                  <div className="card">
                    <div
                      id="headingThree"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          className={isImportant.isCollapsedThree ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsImportant({
                              ...isImportant,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: !isImportant.isCollapsedThree,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                            })
                          }
                        >
                          3. Efficiency Boost
                        </button>
                      </h2>
                    </div>
                    {isImportant.isCollapsedThree ? (
                      <div
                        id="collapseThree"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Whether you’re handling client questions, managing claims, or explaining coverage options, Skyler is here to help, saving you time and reducing your workload

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 4 --> */}
                  <div className="card">
                    <div
                      id="headingFour"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          className={isImportant.isCollapsedFour ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsImportant({
                              ...isImportant,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: !isImportant.isCollapsedFour,
                              isCollapsedFive: false
                            })
                          }
                        >
                          4. Smart Insights
                        </button>
                      </h2>
                    </div>
                    {isImportant.isCollapsedFour ? (
                      <div
                        id="collapseFour"
                        aria-labelledby="headingFour"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Skyler learns from each interaction, improving over time to provide even more personalized and relevant support.

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}


                  {/* <div className="card">
                    <div
                      id="headingFour"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          className="btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"
                          onClick={()=>setIsImportant({...isImportant,isCollapsedOne:false, isCollapsedTwo:false,isCollapsedThree:false, isCollapsedFour:(!isImportant.isCollapsedFour)})}
                        >
                          4. Document Comparison
                        </button>
                      </h2>
                    </div>
                  {isImportant.isCollapsedFour ?  <div
                      id="collapseFour"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                      className="collapse"
                    >
                      <div className="card-body p-4">
                        <p className="font-weight-light m-0">
                          Compare in detail and understand the differences in
                          different legal documents.
                        </p>
                      </div>
                    </div>:null}
                  </div> */}
                </div>
                {/* <!-- End --> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="attorney-section common-padding">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} >
              <div className="atory-section">
                <h2 className="sub-heading text-center">
                  Enhancing Healthcare Accessibility and Easing Clinician
                  Workload With Skyler
                </h2>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="atorny-card">
                <div className="atroy-inner-box">
                  <b>Patient engagement and generating leads</b>
                  <p>
                    The platform encourages active patient involvement by
                    providing personalized health information, generating leads
                    for healthcare services, and promoting a collaborative
                    healthcare approach.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="atorny-card">
                <div className="atroy-inner-box">
                  <b>Information on prescription drugs</b>
                  <p>
                    Skyler offers comprehensive details on prescription
                    medications, including uses, side effects, dosages, and
                    interactions, empowering patients and healthcare professionals
                    to make informed decisions.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} className="mt-3">
              <div className="atorny-card">
                <div className="atroy-inner-box">
                  <b>Sending patient reminders</b>
                  <p>
                    Skyler facilitates automated reminders for
                    appointments, medication schedules, and follow-ups, helping
                    patients stay on track with their healthcare plans and
                    treatments.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} className="mt-3">
              <div className="atorny-card">
                <div className="atroy-inner-box">
                  <b>Ensuring fraud prevention and data security</b>
                  <p>
                    Skyler prioritizes data security by implementing robust
                    measures to safeguard patient information and prevent
                    fraudulent activities, maintaining confidentiality and trust
                    within the healthcare ecosystem.
                  </p>
                </div>
              </div>
          
          </Row>
        </Container>
      </section> */}

      <section className="call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <div className="call-to-action-left">
                <h4>
                  Are you looking for <span>AI Software</span> For insurance
                  Professionals?
                </h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="call-to-action-right">
                {/* <Button
                  className="try-ti-buton get-in-touch animated-btn"
                  onClick={() => globalData.setShowContactUsModal(true)}
                >
                  Reach Us
                </Button> */}
                <Button
                  className="try-ti-buton get-in-touch animated-btn mt-3"
                  onClick={() => 
                    localStorage.getItem("user") 
                      ? navigate("/247-virtual-assistant") 
                      : globalData.setTriggerLogin(true)
                  }
                >
                  <span className="pulse1"></span>
                  Visit Virtual Assistant
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <span ref={pricingSectionRef}></span>
      <Pricing setScrollToPrice={location.state?.setScrollToPrice} /> */}
      {/* <section className="awesome-section common-padding">
        <Container>
          <Row className="mb-5">
            <Col md={12}>
              <h2 className="sub-heading mb-2 text-light">
                Why Choose {Project_Title} For Medical Support & Consultation?
              </h2>
              <p className="text-center">
                At {Project_Title}, we stand out as a leading provider of
                AI-powered healthcare services, committed to delivering precise
                and dependable healthcare solutions. Here's what distinguishes
                us from others:
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">⚡</span>
                <h4>Enhanced Performance</h4>
                <p>
                  {Project_Title} employs advanced algorithms to provide
                  accurate and reliable medical support, ensuring better
                  outcomes for patients and healthcare professionals.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">📱</span>
                <h4>Swift Diagnoses</h4>
                <p>
                  With AI-powered analysis, {Project_Title} offers rapid and
                  precise diagnoses, enabling timely treatment and improving
                  patient care.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">🔐</span>
                <h4>Convenience</h4>
                <p>
                  Accessible from anywhere, {Project_Title} allows users to seek
                  medical advice conveniently, avoiding unnecessary trips to
                  healthcare facilities.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <div className="awesome-inner-box">
                <span className="mb-3 d-block">💸</span>
                <h4>Cost-Effectiveness</h4>
                <p>
                  {Project_Title}'s affordable solutions reduce the financial
                  burden of seeking medical support, making healthcare advice
                  more accessible to all.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* health data section start*/}
      <section className="health-section advantage">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="health-inner-main-box">
                <Row className="align-items-center">
                  <Col lg={4} md={5}>
                    <div className="health-left-area">
                      <img
                        src={require("../../assests/images/lock.gif")}
                        alt="img"
                      />
                      <h2>What Skyler Can Do</h2>
                    </div>
                  </Col>
                  <Col lg={8} md={7}>
                    <div className="health-right-box">
                      <h5>
                        <img
                          src={require("../../assests/images/check-square.gif")}
                          alt="img"
                        />
                        <p><b>Answer Complex Insurance Queries:</b> Whether it's life, health, auto, or property insurance, Skyler has the knowledge to assist.</p>
                      </h5>
                      <h5>
                        <img
                          src={require("../../assests/images/check-square.gif")}
                          alt="img"
                        />
                        <p><b>Assist with Claims:</b> Walk clients through the claims process effortlessly by relying on Skyler's expertise.
                        </p>
                      </h5>
                      <h5>
                        <img
                          src={require("../../assests/images/check-square.gif")}
                          alt="img"
                        />
                        <p><b>Provide Policy Information:</b> From coverage details to exclusions and conditions, Skyler has all the information you need.
                        </p>
                      </h5>
                      {/* <h5>
                        <img
                          src={require("../../assests/images/check-square.gif")}
                          alt="img"
                        />
                        <p><b>Adherence to Data Sovereignty:</b> We uphold regional preferences, guaranteeing that data is stored in accordance with local regulations and cultural sensitivities.

                        </p>
                      </h5>
                      <h5>
                        <img
                          src={require("../../assests/images/check-square.gif")}
                          alt="img"
                        />
                        <p><b>Clear Governance:</b> Our policies are unambiguous, providing users with the information and authority they need to stay well-informed and in control
                        </p>
                      </h5> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* health data section end*/}

      <section className="advantage advantage2 common-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <div className="NFTICALLYAdvantageContent">
                <h2 className="text-light text-center mb-4">FAQs</h2>
                <p></p>
              </div>
              <div className="capabilities-section">
                <div id="accordionExample" className="accordion shadow">
                  {/* <!-- Accordion item 1 --> */}
                  <div className="card">
                    <div
                      id="headingOne1"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne1"
                          aria-expanded="false"
                          aria-controls="collapseOne1"
                          className={isCollapsed.isCollapsedOne ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: isCollapsed.isCollapsedOne ? false : true,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                              isCollapsedSix: false,
                              isCollapsedSeven: false,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          1. What is {Project_Title}?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedOne ? (
                      <div
                        id="collapseOne1"
                        aria-labelledby="headingOne1"
                        data-parent="#accordionExample"
                        className="collapse"
                      // className={isCollapsed ? "collapse show":"collapse" }
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                            {Project_Title} is an AI-powered insurance assistant designed to help insurance agents manage inquiries, claims, and policy details quickly and efficiently. It acts as a smart support tool, providing instant responses to a wide range of insurance-related queries
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 2 --> */}
                  <div className="card">
                    <div
                      id="headingTwo2"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo2"
                          aria-expanded="false"
                          aria-controls="collapseTwo2"
                          className={isCollapsed.isCollapsedTwo ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: !isCollapsed.isCollapsedTwo,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                              isCollapsedSix: false,
                              isCollapsedSeven: false,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          2. How can {Project_Title} assist me as an insurance agent?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedTwo ? (
                      <div
                        id="collapseTwo2"
                        aria-labelledby="headingTwo2"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          {Project_Title} can answer detailed insurance questions, guide you through claims processes, explain policy details, and even provide insights into complex insurance terms. It helps you manage tasks more effectively, freeing up time to focus on client relationships
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}

                  {/* <!-- Accordion item 3 --> */}
                  <div className="card">
                    <div
                      id="headingThree3"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree3"
                          aria-expanded="false"
                          aria-controls="collapseThree3"
                          className={isCollapsed.isCollapsedThree ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: !isCollapsed.isCollapsedThree,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                              isCollapsedSix: false,
                              isCollapsedSeven: false,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          3. What kind of insurance information can {Project_Title} handle?
                          support?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedThree ? (
                      <div
                        id="collapseThree3"
                        aria-labelledby="headingThree3"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Skyler can assist with various types of insurance, including life, health, auto, home, and commercial policies. It provides answers on policy coverage, exclusions, conditions, claim procedures, and more.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <!-- End --> */}

                  <div className="card">
                    <div
                      id="headingFour4"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour4"
                          aria-expanded="false"
                          aria-controls="collapseFour4"
                          className={isCollapsed.isCollapsedFour ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: !isCollapsed.isCollapsedFour,
                              isCollapsedFive: false,
                              isCollapsedSix: false,
                              isCollapsedSeven: false,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          4. Is {Project_Title} available 24/7?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedFour ? (
                      <div
                        id="collapseFour4"
                        aria-labelledby="headingFour4"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Yes, Skyler is available 24/7 to provide support. You can rely on Skyler to assist you at any time, even after normal business hours.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="card">
                    <div
                      id="headingFour4"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour4"
                          aria-expanded="false"
                          aria-controls="collapseFour4"
                          className={isCollapsed.isCollapsedFive ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: !isCollapsed.isCollapsedFive,
                              isCollapsedSix: false,
                              isCollapsedSeven: false,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          5. Does {Project_Title} store my clients' sensitive information?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedFive ? (
                      <div
                        id="collapseFour4"
                        aria-labelledby="headingFour4"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          No, Skyler is built with privacy and security in mind. It does not store any personal client data and complies with industry standards to protect sensitive information.

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="card">
                    <div
                      id="headingFour4"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour4"
                          aria-expanded="false"
                          aria-controls="collapseFour4"
                          className={isCollapsed.isCollapsedSix ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                              isCollapsedSix: !isCollapsed.isCollapsedSix,
                              isCollapsedSeven: false,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          6. Can {Project_Title} help with claims processing?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedSix ? (
                      <div
                        id="collapseFour4"
                        aria-labelledby="headingFour4"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Absolutely! Skyler can guide you through the claims process, providing step-by-step assistance and clarifying any doubts you might have regarding documentation or procedures.

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="card">
                    <div
                      id="headingFour4"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour4"
                          aria-expanded="false"
                          aria-controls="collapseFour4"
                          className={isCollapsed.isCollapsedSeven ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                              isCollapsedSix: false,
                              isCollapsedSeven: !isCollapsed.isCollapsedSeven,
                              isCollapsedEight: false,
                            })
                          }
                        >
                          7. How does {Project_Title} learn and improve over time?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedSeven ? (
                      <div
                        id="collapseFour4"
                        aria-labelledby="headingFour4"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Skyler uses machine learning to improve its responses with every interaction. As it encounters new queries and information, it becomes smarter, ensuring that it provides more accurate and tailored responses over time

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="card">
                    <div
                      id="headingFour4"
                      className="card-header bg-white shadow-sm border-0"
                    >
                      <h2 className="mb-0">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour4"
                          aria-expanded="false"
                          aria-controls="collapseFour4"
                          className={isCollapsed.isCollapsedEight ? "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link" : "btn btn-link text-dark font-weight-bold text-uppercase collapsible-link collapsed"}
                          onClick={() =>
                            setIsCollapsed({
                              ...isCollapsed,
                              isCollapsedOne: false,
                              isCollapsedTwo: false,
                              isCollapsedThree: false,
                              isCollapsedFour: false,
                              isCollapsedFive: false,
                              isCollapsedSix: false,
                              isCollapsedSeven: false,
                              isCollapsedEight: !isCollapsed.isCollapsedEight,
                            })
                          }
                        >
                          8. How can I get started with {Project_Title}?
                        </button>
                      </h2>
                    </div>
                    {isCollapsed.isCollapsedEight ? (
                      <div
                        id="collapseFour4"
                        aria-labelledby="headingFour4"
                        data-parent="#accordionExample"
                        className="collapse"
                      >
                        <div className="card-body p-4">
                          <p className="font-weight-light m-0">
                          Simply start a conversation! You can engage with Skyler through the chat feature on the website. It’s ready to assist you with all your insurance-related questions and tasks.

                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <!-- End --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="modal-area-box"
        show={globalData.showContactUsModal}
        onHide={() => {
          globalData.setShowContactUsModal(false);
          setIsCaptchaValue(false)
          setContactUsFields({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "",
          });
          setContactUsFieldsErrors({
            firstName: false,
            lastName: false,
            email: false,
            message: false,
            phone: false,
          });
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes">
            <div className="left-chat-box">
              <div className="chat-history-header"></div>
              <div className="right-chat-box-dropdown call-action">
                <div>
                  <Form onSubmit={handleContactUsSubmit}>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.firstName ? "border-red" : ""
                        }
                        placeholder="Enter First Name"
                        value={contactUsFields.firstName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              firstName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Last Name"
                        className={
                          contactUsFieldsErrors.lastName ? "border-red" : ""
                        }
                        value={contactUsFields.lastName}
                        maxLength={30}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              lastName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                lastName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                        }
                        className={
                          contactUsFieldsErrors.phone
                            ? "border-red phone-input"
                            : "phone-input"
                        }
                        placeholder="Enter phone number"
                        value={contactUsFields.phone}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          if (e.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else if (e.trim().length < 10) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          contactUsFieldsErrors.email ? "border-red" : ""
                        }
                        placeholder="Enter Email"
                        value={contactUsFields.email}
                        maxLength={100}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              email: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else if (!EMAILREGEX.test(e.target.value)) {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                email: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Message"
                        className={
                          contactUsFieldsErrors.message ? "border-red" : ""
                        }
                        value={contactUsFields.message}
                        maxLength={1000}
                        onChange={(e) => {
                          setContactUsFields((prevObj) => {
                            return {
                              ...prevObj,
                              message: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: true,
                              };
                            });
                          } else {
                            setContactUsFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                message: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    {/* email used for captcha tushar.netset */}
                    {/* <Form.Group
                      controlId="formFile"
                      className="mb-2 captcha-size w-100"
                    >     
                      <ReCAPTCHA
                        style={myStyles}
                        sitekey="6LeKLXAoAAAAAH20G0gfS6AaDgK6zhPjbcNBDeZW"
                        onChange={onChange}
                      />
                    </Form.Group> */}
                    <br />
                    <button
                      className="try-ti-buton"
                      // disabled={isCaptchaValue ? false : true}
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;
