import axios from "axios"
import { baseUrl, openAiImageVariationUrl, OPENAI_ORGANIZATION_ID, OPENAI_SECRET_API_KEY, STRIPE_KEY, stripe_prod_id } from "../config/config";

const jsonFileHeaders = {
    headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${OPENAI_SECRET_API_KEY}`,
        "OpenAI-Organization": OPENAI_ORGANIZATION_ID
    }
}
const jsonJsonHeaders = {
    headers: {
        "Content-Type": "application/json",

    }
}
const token = localStorage.getItem("token")
const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
// alert(token)
const jsonAuthHeaders = {
    headers: {
        "Authorization": `Token ${token}`
    }
}

const staticHeader = {
    headers: {
        "Authorization":"ae36c27d13a22623e9da98e94120deedbe4c4a0751fd5cfb7989a78cb69767fe"
    }
}

// "Content-Type": "application/x-www-form-urlencoded",
const jsonStripeHeaders = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${STRIPE_KEY}`
    }
}

export const openAiService = {
    getChatResponse,
    imageVariation,
    registerUser,
    getChatHistory,
    bookmarkHistory,
    getBookmarkHistory,
    saveCard,
    getCards,
    buySubscription,
    listSubscriptions,
    comparePdf,
    getSubscription,
    updatePlan,
    getPlan,
    cancelSubscription,
    contactUs,
    deleteCard,
    createContract,
    delete_pdf,
    user_detail,
    savePdf,
    getFiles,
    getProfiles,
    getDocumentChat,
    remove_bookmark,
    delete_history,
    loginValidate,
    login,
    all_user,
    book_appointment,
    // all_appointments,
    markAsComplete,
    getProfileById,
    getRequestFor,
    getAppointments,
    getProviders,
    getUserAppointmentHistory,
    uploadNotes,
    addSchedule,
    getSchedule,
    getAvailableDays,
    getAvailableSlots,
    getRecommendedSlots,
    send_otp,
    verify_otp,
    getHabits,
    addHabits,
    getAppointmentId,
    updatePrescription,
    shareOnEmail,
    getSuggestedMedicine,
    revisitAppointment,
    getAllUpcompingAppointments,
    getFamilyMembers,
    addFamilyMember,
    updateFamilyMember,
    getMembersHabits,
    addMemberHabits,
    getNotesHistory,
    deleteNotesHistory,
    updateResponse,
    getSingleNote,
    getContentFromAudio,
    dashboardCount,
    graphDataApi,
    getCalenderEvents,
    getAllReports,
    deleteReport,
    shareReportInsight,
    addReportInsight,
    saveReportName,
    callToAssistant,
    getCallLogs,
    getDiagnoses,
    imageAnalyzer

}

function getChatResponse(params) {
    return axios.post(`${baseUrl}/prompt/`, params, jsonAuthHeaders);
}
function user_detail(id, params) {
    return axios.put(`${baseUrl}/api/user/${id}/`, params, jsonAuthHeaders)
}

function imageVariation(params) {
    return axios.post(openAiImageVariationUrl, params, jsonFileHeaders);
}

function registerUser(params) {
    return axios.post(`${baseUrl}/api/login/`, params);
}

function getChatHistory(type, page) {
    return axios.get(`${baseUrl}/api/user-chat-thread?thread=${type}&pagepage=${page}`, jsonAuthHeaders);
}

function getFiles(type, page) {
    return axios.get(`${baseUrl}/api/user-chat-thread?thread=${type}&pagepage=${page}`, jsonAuthHeaders);
}
function getProfiles() {
    return axios.get(`${baseUrl}/api/user/my-profile/`, jsonAuthHeaders);
}

function bookmarkHistory(params) {
    return axios.post(`${baseUrl}/api/user-chat-thread/mark-bookmarked/`, params, jsonAuthHeaders);
}

function getBookmarkHistory(id) {
    return axios.get(`${baseUrl}/api/user-chat-thread/${id}`, jsonAuthHeaders);
}
function getDocumentChat(id) {
    return axios.get(`${baseUrl}/api/user-chat-thread/${id}`, jsonAuthHeaders);
}


function saveCard(params) {
    return axios.post(`${baseUrl}/customer-payment-methods/`, params, jsonAuthHeaders);
}

function getCards() {
    return axios.get(`${baseUrl}/customer-payment-methods/`, jsonAuthHeaders);
}

function buySubscription(params) {
    return axios.post(`${baseUrl}/create-subscription/`, params, jsonAuthHeaders);
}
function updatePrescription(params) {
    return axios.post(`${baseUrl}/prescription/`, params, jsonAuthHeaders);
}
function listSubscriptions() {
    return axios.get(`https://api.stripe.com/v1/plans?active=true&product=` + stripe_prod_id, jsonStripeHeaders);
}

function getSubscription(subId) {
    return axios.get(`https://api.stripe.com/v1/subscriptions/${subId}`, jsonStripeHeaders)
}

function updatePlan(id, params) {
    return axios.post(`https://api.stripe.com/v1/plans/${id}`, params, jsonStripeHeaders);
}

function getPlan(id) {
    return axios.get(`https://api.stripe.com/v1/plans/${id}`, jsonStripeHeaders);
}

function cancelSubscription(id) {
    return axios.post(`${baseUrl}/cancel-subscription/${id}`, {}, jsonAuthHeaders);
}

function comparePdf(params) {
    return axios.post(`${baseUrl}/api/upload-pdf/`, params, jsonAuthHeaders);
}

function contactUs(params) {
    return axios.post(`${baseUrl}/api/contact-us/`, params, jsonAuthHeaders);
}

function deleteCard(params) {
    return axios.post(`${baseUrl}/delete_stripe_card/`, params, jsonAuthHeaders);
}

function createContract(params) {
    return axios.post(`${baseUrl}/create_contract/`, params, jsonAuthHeaders);
}
function savePdf(params) {
    return axios.post(`${baseUrl}/save_pdf/`, params, jsonAuthHeaders);
}

function shareOnEmail(params) {
    return axios.post(`${baseUrl}/share_on_email`, params, jsonAuthHeaders);
}

function delete_pdf(id) {
    return axios.delete(`${baseUrl}/api/user-chat-thread/${id}/`, jsonAuthHeaders);
}
function delete_history(id) {
    return axios.delete(`${baseUrl}/api/user-chat-thread/${id}`, jsonAuthHeaders);
}
function remove_bookmark(id) {
    return axios.get(`${baseUrl}/remove_bookmark/${id}`, jsonAuthHeaders);
}

function loginValidate(setloginFieldsErrs, loginFields) {
    let formValidated = true;
    if (loginFields.email.trim() === "") {
        formValidated = false;
        setloginFieldsErrs((obj) => {
            return {
                ...obj,
                email: true,
                emailErr: "Please enter email address",
                user_typeErr: ""
            };
        });
        console.log('kkk', setloginFieldsErrs);
    } else if (!EMAILREGEX.test(loginFields.email)) {
        console.log(EMAILREGEX.test(loginFields.email), "valid email field")
        formValidated = false;
        setloginFieldsErrs((obj) => {
            return {
                ...obj,
                emailErr: "Enter a valid email address",

            };
        });
    } else if (loginFields.password.trim() === "") {
        formValidated = false;
        setloginFieldsErrs((obj) => {
            return {
                ...obj,
                password: true,
                emailErr: "",
                passwordErr: "Enter your password"
            };
        });
    }
    return formValidated
}
function login(params) {
    return axios.post(`${baseUrl}/api/login/`, params, jsonJsonHeaders);
}
function all_user() {
    return axios.get(`${baseUrl}/all_user`, jsonAuthHeaders);
}

// function all_appointments(page,status){
//     return axios.get(`${baseUrl}/appointment?page=${page}&status=${status}`,  jsonAuthHeaders);
// }
// function book_appointment(params){
//     return axios.post(`${baseUrl}/book_appointment/`, params, jsonAuthHeaders);
// }
function book_appointment(params) {
    return axios.post(`${baseUrl}/appointment/`, params, jsonAuthHeaders);
}

function markAsComplete(id, params) {
    return axios.put(`${baseUrl}/appointment/${id}/`, params, jsonAuthHeaders);
}

function getProfileById(id) {
    return axios.get(`${baseUrl}/user_data/${id}`, jsonAuthHeaders);
}

function getRequestFor() {
    return axios.get(`${baseUrl}/doctor_type/`, jsonAuthHeaders);
}

function getAppointments(page, status, type) {
    if(type){
        status = status + ','+type;
     }
    return axios.get(`${baseUrl}/appointment/all_appoint/?page=${page}&status=${status}`, jsonAuthHeaders);
}

function getAllUpcompingAppointments() {
    
    return axios.get(`${baseUrl}/appointment/upcoming/`, jsonAuthHeaders);
}

function dashboardCount() {
    return axios.get(`${baseUrl}/appointment/dashboard/`, jsonAuthHeaders);
}

function graphDataApi(type, month, year) {
    return axios.get(`${baseUrl}/appointment/graph_data/?type=${type}&month=${month}&year=${year}`, jsonAuthHeaders);
}

function getProviders() {
    return axios.get(`${baseUrl}/appointment/provider/`, jsonAuthHeaders);
}

function getUserAppointmentHistory(user_id, type) {
    return axios.get(`${baseUrl}/appointed_user_detail/${user_id}/?type=${type}`, jsonAuthHeaders);

}

function getAppointmentId(id, type) {
    return axios.get(`${baseUrl}/appointment/${id}/`, jsonAuthHeaders);

}
function uploadNotes(params) {
    return axios.post(`${baseUrl}/create_note/`, params, jsonAuthHeaders);

}

function addSchedule(params) {
    return axios.post(`${baseUrl}/schedule/`, params, jsonAuthHeaders);
}
function getSchedule() {
    return axios.get(`${baseUrl}/schedule/`, jsonAuthHeaders);

}
function getAvailableDays() {
    return axios.get(`${baseUrl}/schedule/day_slots/`, jsonAuthHeaders);
}
function getAvailableSlots(date) {
    return axios.get(`${baseUrl}/schedule/available_slots/?date=${date}`, jsonAuthHeaders);
}

function getRecommendedSlots(openaiId) {
    return axios.get(`${baseUrl}/schedule/recommended_slots/?open_ai_id=${openaiId}`, jsonAuthHeaders);
}

function send_otp(params) {
    return axios.post(`${baseUrl}/send_otp/`, params, jsonAuthHeaders);
}
function verify_otp(params) {
    return axios.post(`${baseUrl}/verify_otp/`, params, jsonAuthHeaders);
}
function getHabits() {
    return axios.get(`${baseUrl}/habit/`, jsonAuthHeaders);
}

function addHabits(params) {
    return axios.post(`${baseUrl}/habit/`, params, jsonAuthHeaders);
}

function getSuggestedMedicine(keyword) {
    return axios.get(`https://drugs-api.ecomempire.in/search?search=${keyword}`);
}

function revisitAppointment(params) {
    return axios.post(`${baseUrl}/revisit_appoitment`, params, jsonAuthHeaders)
}

function getFamilyMembers(){
    return axios.get(`${baseUrl}/member/my_members/`, jsonAuthHeaders)
}

function addFamilyMember(params){
    return axios.post(`${baseUrl}/member/`, params, jsonAuthHeaders)
}

function updateFamilyMember(id,params){
    return axios.put(`${baseUrl}/member/${id}/`, params, jsonAuthHeaders)
}

function getMembersHabits(id){
    return axios.get(`${baseUrl}/habit/${id}/member_habit/`,jsonAuthHeaders)  
}

function addMemberHabits(id,data){
    return axios.post(`${baseUrl}/habit/${id}/add_member_habit/`,data ,jsonAuthHeaders)   
}

function getNotesHistory(id){
    return axios.get(`${baseUrl}/appoint_prompt/?appointment=${id}` ,jsonAuthHeaders)
}

function deleteNotesHistory(id){
    return axios.delete(`${baseUrl}/appoint_prompt/${id}/` ,jsonAuthHeaders)
}

function updateResponse(param, id){
    return axios.patch(`${baseUrl}/appoint_prompt/${id}/`,param ,jsonAuthHeaders)
}

function getSingleNote(id){
    return axios.get(`${baseUrl}/appoint_prompt/${id}/`,jsonAuthHeaders)
}

function getContentFromAudio(file){
   return axios.post(`${baseUrl}/audio_convertor/`, file, jsonAuthHeaders);
}

function getCalenderEvents(month, year){
    return axios.get(`${baseUrl}/appointment/calendar/?month=${month}&year=${year}`, jsonAuthHeaders)
}

function getAllReports(page){
    return axios.get(`${baseUrl}/report_insight/?page=${page}`, jsonAuthHeaders)
}

function deleteReport(id){
    return axios.delete(`${baseUrl}/report_insight/${id}/`, jsonAuthHeaders)
}

function shareReportInsight(id, params){
     return axios.post(`${baseUrl}/report_insight/${id}/share/`, params, jsonAuthHeaders)
}

function addReportInsight(params){
    return axios.post(`${baseUrl}/report_insight/`, params, jsonAuthHeaders) 
}

function saveReportName(id,params){
    return axios.put(`${baseUrl}/report_insight/${id}/`, params, jsonAuthHeaders) 
} 

function callToAssistant(id){
    return axios.post(`${baseUrl}/start_call/${id}/`,{}, jsonAuthHeaders) 
    //https://staging-api.aihealthassistant.pro/start_call/%7Bappointment_id%7D/
}

function getCallLogs(id){
     return axios.get(`${baseUrl}/appointment/${id}/call_log/`, jsonAuthHeaders);
}

function getDiagnoses(id){
    return axios.get(`${baseUrl}/appointment/${id}/diagnose/`, jsonAuthHeaders);
}

/* image imageAnalyzer */

function imageAnalyzer(params) {
    return axios.post(`${baseUrl}/image-analyzer/`, params, jsonAuthHeaders)
}