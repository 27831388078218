import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const CommonLayout = (props) => {
  return (
    <>
      {console.log('window location', window.location.pathname.split('/')[0])}

      {localStorage.getItem('userType') == 'doctor' && 
      !window.location.href.includes('userdetails') &&
      window.location.pathname.split('/')[1] !== "" &&
      !window.location.href.includes('availability') &&
      !window.location.href.includes('user-medication') &&
      !window.location.href.includes('history-generate') &&
      !window.location.href.includes('ala-care') &&
       <Sidebar />
      }
      <Header />
      {props.children}
      <Footer />
    </>
  );
};

export default CommonLayout;
