import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import ReCAPTCHA from "react-google-recaptcha";
import { RotatingLines } from "react-loader-spinner";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Swal from "sweetalert2";
import {
  DEFAULT_PLAN_ID,
  PREMIUM_PLAN_ID,
  Project_Title,
  baseUrl,
  chatSocketUrl,
  prompts,
  sampleQuestions,
} from "../../config/config";
import { context } from "../../GlobalContext/GlobalContext";
import { openAiService } from "../../services/openai.services";
import { CommonFunction } from "../common/functions";
import { Loading } from "../common/loader";
import { capitalizeFirstLetter } from "../utils/localStorage";

const ct = require("countries-and-timezones");
const minDate = new Date();
minDate.setDate(minDate.getDate() - 1);
let current = new Date();
const currentDateToSelect = new Date(
  current.setTime(current.getTime() + 1 * 60 * 60 * 1000)
);

const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];

const Chat = () => {
  const [id, setId] = useState();
  const userId =
    localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.user_id
      ? JSON.parse(localStorage.getItem("user"))?.user_id
      : localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user"))?.id
        ? JSON.parse(localStorage.getItem("user"))?.id
        : id;
  localStorage.removeItem("navigate");
  localStorage.removeItem("redirectUrl");
  const navigate = useNavigate();
  const globalData = useContext(context);
  const globalDataRef = useRef();
  const input_focused = useRef();
  globalDataRef.current = globalData;
  var allDays = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${chatSocketUrl}${userId}/`
  );
  const [show, setShow] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [chat, setChat] = useState([]);
  const [clicked, setClicked] = useState(true);
  const [chatResponse, setChatResponse] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [openaiId, setOpenaiId] = useState("");
  const [isBookmarked, setIsBookmarked] = useState(0);
  const [history, setHistory] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [showChatResponse, setshowChatResponse] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [chatTriggered, setChatTriggered] = useState(false);
  const [trigerScroll, setTriggerScroll] = useState(false);
  const [elementHeight, setElementHeight] = useState();
  const [pdfFile, setPdfFile] = useState([]);
  const [pdfFile0, setPdfFile0] = useState();
  const [pdfFile1, setPdfFile1] = useState();
  const [showPdfPrompt, setShowPdfPrompt] = useState(false);
  const [showComparePdfPrompt, setCompareShowPdfPrompt] = useState(false);
  const [upGradePlanPopupModal, setUpgradePlanPopupModal] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [departmentval, setdepartment] = useState();
  const [keepScrollingToBottom, setKeepScrollingToBottom] = useState(false);
  const phoneno = /^\d{12}$/;
  let bookmarkDisabled = true;
  const generatedAnswer = useRef("");
  const [counterHtml, setcountHtml] = useState("");
  const interval = useRef(null);
  const [providers, setProviders] = useState([]);
  const [files, setFiles] = useState([]);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const bottomRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [isUserDobExists, setIsUserDobExists] = useState();
  const [requestFor, setRequestFor] = useState([]);
  const [userSuggestion, setUserSuggestion] = useState([]);
  const [suggesteduserId, setSuggesteduserId] = useState();
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [sendingResponse, setSendingResponse] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [enableDays, setEnableDays] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [recommendedSlots, setRecommendedSlots] = useState([]);
  const [consine, setConsine] = useState(false);
  const [onMobileClick, setOnMobileClick] = useState(false);

  const [consentShow, setConsentShow] = useState(false);
  const [allowTreatment, setAllowTreatment] = useState(false);
  const [allowRisk, setAllowRisk] = useState(false);
  const [infoAccurate, setInfoAccurate] = useState(false);
  const [allowHealthData, setAllowHealthData] = useState(false);

  const [phoneNo, setPhoneNo] = useState("");
  const [reason, setReason] = useState("");
  const [isExtra, setIsExtra] = useState(false);
  const [providerId, setProviderId] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSeemar, setIsSeemar] = useState(false);
  const [buttonText, setButtonText] = useState(
    require("../../assests/images/menu-icon.svg").default
  );
  const [members, setMembers] = useState([]);
  const [memberSelected, setMemberSelected] = useState();
  const [isActive, setIsActive] = useState("me");
  const [memberName, setMemberName] = useState("");

  const [memberModal, setMemberModal] = useState(false);


  const [appointmentFields, setAppointmentFields] = useState({
    reason: "",
    insurance_type: "self",
    insurance_type_text: "",
    appointment_for: "me",
    patient_gender: "",
    patient_name: "",
    patient_age: "",
    phone: "",
    selectedDate: new Date(),
    request_for: "",
    report_files: [],
    slot: "",
    insurance_number: "",
  });

  function ChangeText(text) {
    if (text == require("../../assests/images/menu-icon.svg").default) {
      setButtonText(require("../../assests/images/close-icon.svg").default);
    } else {
      setButtonText(require("../../assests/images/menu-icon.svg").default);
    }
  }
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);
  const [appointmentFieldsErrors, setAppointmentFieldsErrors] = useState({
    reason: false,
    insurance_type: false,
    insurance_type_text: false,
    appointment_for: false,
    patient_gender: false,
    patient_name: false,
    patient_age: false,
    phone: false,
    selectedDate: false,
    request_for: false,
    report_files: false,
    slot: false,
    insurance_number: false,
  });

  function onChange(value) {
    setIsCaptchaValue(value);
  }

  useEffect(() => {
    if (!localStorage.getItem("user") || localStorage.getItem("user") == "") {
      localStorage.removeItem("token");
    }
  }, []);

  useEffect(() => {
    getProfiles();
  }, []);

  useEffect(() => {
    setQueryString(transcript);

    if (isListening) {
      document.getElementById("input_focused").focus();
      const textInputEl = input_focused.current;
      textInputEl.scrollTop = textInputEl.scrollHeight;
    }
  }, [transcript]);


  useEffect(() => {
    if (lastMessage !== null) {
      setShowLoader(false);
      setshowChatResponse(true);
      setUploadedImageFile("");
      setUploadedImage("");
      let messageResponse = JSON.parse(lastMessage.data);
      if (!messageResponse.stream) {
        getHistory();
        setshowChatResponse(false);

        setChatTriggered(false);
        let answer = generatedAnswer.current;
        setChat((prevObj) => [...prevObj, { type: "answer", text: answer }]);
        generatedAnswer.current = "";
        let elm = document.getElementById("chat-response-id");
        if (elm) {
          elm.innerHTML = "";
        }
        if (localStorage.getItem("plan")) {
          getHistory();
        }
      } else {
        setOpenaiId(messageResponse.thread);
        let elm = document.getElementById("chat-response-id");
        if (elm) {
          elm.innerHTML = messageResponse.message;
        }
        generatedAnswer.current = messageResponse.message;
        if (
          element.scrollHeight - (element.scrollTop + element.clientHeight) >
          200
        ) {
          setKeepScrollingToBottom(false);
        } else {
          setKeepScrollingToBottom(true);
        }
        setElementHeight(element.scrollHeight);
      }
    }
  }, [lastMessage]);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedFile, setUploadedImageFile] = useState();

  async function imageUploading() {
    const formData = new FormData();
    formData.append("image", uploadedFile);
    formData.append("prompt", queryString);
    formData.append("openai_id", openaiId);
    if (isActive === "someone") {
      formData.append("asking_for", memberSelected);
    } else {
      formData.append("asking_for", isActive);
    }

    await openAiService.imageAnalyzer(formData).then((response) => {
      try {
        setUploadedImage("");
        setUploadedImageFile("");
      } catch (error) {
      }
    });
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const [pageNo, setPageNo] = useState(1);
  const [totalHistoryRecords, setTotalHistoryRecords] = useState(0);

  useEffect(() => {
    getHistory();
  }, [pageNo]);

  useEffect(() => {
    if (chatTriggered) {
      setTimeout(() => {
        typeWriterEffect();
      }, 300);
    }
  }, [chatResponse]);

  const element = document.getElementById("chatbox-body-id");
  const leftChatElement = document.getElementById("left-chat-boxes-id");

  leftChatElement?.addEventListener("scroll", onBottomScroll, false);

  function onBottomScroll() {
    if (
      leftChatElement?.scrollHeight ===
      leftChatElement?.scrollTop + leftChatElement?.clientHeight
    ) {
      if (totalHistoryRecords > history.length) {
        setPageNo(pageNo + 1);
      }
    }
  }

  useEffect(() => {
    if (keepScrollingToBottom) {
      element?.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [element?.scrollHeight, elementHeight, trigerScroll]);

  function changeData(type, e, name) {
    if (type == "self") {
      if (openaiId) {
        if (isActive == "me") {
          setIsActive("me");
          setMemberSelected();
          setMemberName("");
        } else {
          startNewChat();
          setIsActive("me");
          setMemberSelected();
          setMemberName("");
        }
      } else {
        setMemberName("");
        setMemberSelected();
        setIsActive("me");
      }
    } else {
      if (openaiId) {
        startNewChat();
        setMemberSelected(e.target.value);

        setIsActive("someone");
        setMemberName(name);
        setShowAdd(false);
      } else {
        setIsActive("someone");
        setMemberSelected(e.target.value);
        setMemberName(name);
        setShowAdd(false);
      }
    }
  }

  function getProfiles() {
    openAiService
      .getProfiles()
      .then((res) => {
        setPhoneNo(res.data?.data.phone ? res.data?.data.phone : "");
        setId(res.data?.data?.id);
        setIsUserDobExists(res.data?.data.dob);
      })
      .catch((error) => {
      });
  }

  function removeSecondFromString(time) {
    var modify = time.split(":");
    return modify[0] + ":" + modify[1];
  }

  function getAvailableSlots(date) {
    openAiService
      .getAvailableSlots(date)
      .then((result) => {

        var output = result?.data?.data?.filter(
          (s) => new Date(date + " " + s.time)?.getTime() > new Date().getTime()
        );
        setAvailableSlots(output);
      })
      .catch((err) => console.log(err));
  }


  const hideConsent = () => {
    setConsentShow(false);
    setIsChecked(false);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setConsentShow(true);
    } else {
      setAllowTreatment(false);
      setAllowRisk(false);
      setInfoAccurate(false);
      setAllowHealthData(false);
      setConsentShow(false);
    }
    setIsChecked((current) => !current);
  };

  function getHistory() {
    openAiService
      .getChatHistory("text", pageNo)
      .then((res) => {
        setHistoryLoading(false);
        setTotalHistoryRecords(res.data?.totalCount);
        setChatCount(res.data.history);
        let historyArray = res.data?.data;
        let bookmarkArray = res.data?.data.filter(
          (obj) => obj.is_bookmarked === true
        );
        if (historyArray.length > 0) {
          setHistory([...historyArray]);
          setBookmarks([...bookmarkArray]);
        } else {
          setPageNo(pageNo > 0 ? pageNo - 1 : 0);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }
  let formValidated = true;

  const handleDateChange = (date) => {
    getAvailableSlots(moment(date).format("YYYY-MM-DD"));
    setAppointmentFields({ ...appointmentFields, selectedDate: date });
  };

  const isDayEnabled = (date) => {
    return enableDays.some((enabledDay) => enabledDay === date.getDay());
  };


  function getChat(id) {
    setShowLoader(false);
    setshowChatResponse(false);
    setOpenaiId(id);
    openAiService
      .getBookmarkHistory(id)
      .then((res) => {
        let history = res.data.data;
        setChat([]);
        history.forEach((item) => {
          setReason(item.question);

          setChat((prevObj) => [
            ...prevObj,
            { type: "question", text: item.question },
            { type: "answer", text: item.response },
          ]);

          if (item.image) {
            setChat((prevObj) => [
              ...prevObj,
              { type: "image", text: baseUrl + item.image },
            ]);
          }
        });

        setIsBookmarked(history[0].is_bookmarked ? 1 : 0);
        let children = res.data.children;
        children?.forEach((historyObj) => {
          if (historyObj.image) {
            setChat((prevObj) => [
              ...prevObj,
              { type: "image", text: baseUrl + historyObj.image },
            ]);
          }
          setChat((prevObj) => [
            ...prevObj,
            { type: "question", text: historyObj.question },
          ]);
          setChat((prevObj) => [
            ...prevObj,
            { type: "answer", text: historyObj.response },
          ]);
        });


        if (
          element.scrollHeight - (element.scrollTop + element.clientHeight) >
          200
        ) {
          setKeepScrollingToBottom(false);
        } else {
          setKeepScrollingToBottom(true);
        }
        setElementHeight(element.scrollHeight);
        setTriggerScroll(!trigerScroll);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }


  var i = 0;
  var speed = 30;
  function typeWriterEffect() {
    if (i < chatResponse.length) {
      let elm = document.getElementById("chat-response-id");
      if (elm) {
        elm.innerHTML += chatResponse.charAt(i);
      }
      if (
        element.scrollHeight - (element.scrollTop + element.clientHeight) >
        200
      ) {
        setKeepScrollingToBottom(false);
      } else {
        setKeepScrollingToBottom(true);
      }
      setElementHeight(element.scrollHeight);
      i++;
      setTriggerScroll(!trigerScroll);
      setTimeout(typeWriterEffect, speed);
    } else {
      setshowChatResponse(false);
      setChatTriggered(false);
      setChat((prevObj) => [
        ...prevObj,
        { type: "answer", text: chatResponse },
      ]);
      setChatResponse("");
    }
  }

  const handleOptionChange = (e) => {
    setAppointmentFields({
      ...appointmentFields,
      insurance_type: e.target.value,
    });
  };

  const handleSendMessage = useCallback((data) => sendMessage(data), []);

  const askQuestion = (query) => {
    setUploadedImage("");

    setQueryString("");
    resetTranscript();
    setTriggerScroll(!trigerScroll);
    setClicked(false);
    setRecommendedSlots([]);
    setIsExtra(false);
    setChat((prevObj) => {
      if (uploadedFile) {
        return [
          ...prevObj,
          { type: "image", text: uploadedImage },
          { type: "question", text: query },
        ];
      } else {
        return [...prevObj, { type: "question", text: query }];
      }
    });
    setShowLoader(true);
    setChatTriggered(true);
    let data = {
      thread: openaiId,
      specialist_for: NaN,
      question: query,
      messages: [
        ...chat.map((d) => {
          let data = {};
          if (d.type === "question") {
            data.role = "user";
          } else {
            data.role = "assistant";
          }
          data.content = d.text;
          return data;
        }),
        { role: "user", content: query },
      ],
    };
    if (uploadedFile) {
      imageUploading();
      var element = document.getElementById("chat_file");
      if (element) {
        element.value = "";
      }
    } else {
      handleSendMessage(JSON.stringify(data));
    }
  };

  function comparePdf(uploadedFile, uploadedFile1) {
    setQueryString("");
    setShowPdfPrompt(false);
    setCompareShowPdfPrompt(false);
    if (uploadedFile1) {
      let query = `Compare Agreement - ${pdfFile0.name} and ${pdfFile1.name}`;
      setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
    } else {
      let query = "";
      pdfFile.map((filePdf, i) => {
        if (query != "") {
          query = query + " & " + ` ${filePdf.name}`;
        } else {
          query = `${filePdf.name}`;
        }
        if (parseInt(i + 1) == pdfFile.length) {
          query = "Summarize -" + query;
          setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
        }
      });
    }
    setShowLoader(true);
    setChatTriggered(true);

    let data = new FormData();
    for (let i = 0; i < uploadedFile.length; i++) {
      data.append(`pdfFile`, uploadedFile[i]);
    }

    if (true) {
      data.append("openai_id", openaiId);
    }
    if (uploadedFile1) {
      data.append("pdfFile2", uploadedFile1);
    }
    openAiService
      .comparePdf(data)
      .then((res) => {
        if (uploadedFile.length > 0) {
          Swal.fire("Success", "File uploaded successfully", "success");
        } else {
          let documentElm1 = document.getElementById("upload-pdf-1");
          if (documentElm1) {
            documentElm1.value = "";
          }
          let documentElm2 = document.getElementById("upload-pdf-2");
          if (documentElm2) {
            documentElm2.value = "";
          }
          let documentElm3 = document.getElementById("upload-pdf-3");
          if (documentElm3) {
            documentElm3.value = "";
          }
          setshowChatResponse(true);
          let chatresponse = res.data?.data;
          setOpenaiId(res.data.thread);
          setChatResponse(chatresponse);
          getHistory();
          setShowLoader(false);
          setPdfFile();
          setPdfFile1();
          setCompareShowPdfPrompt(false);
          setShowPdfPrompt(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);

        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        } else {
          setChat((prevObj) => [
            ...prevObj,
            { type: "answer", text: "Unable to generate response." },
          ]);
        }
      });
  }
  const upgradeToPremium = () => {
    openAiService.getPlan(PREMIUM_PLAN_ID).then((res) => {
      let plan = res.data;
      navigate("/card_details", { state: { plan: plan } });
    });
  };
  const handleContactUsSubmit = (e) => {
    e.preventDefault();

    if (appointmentFields.reason.trim() === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          reason: true,
        };
      });
    }
    if (appointmentFields.reason.length > 500) {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          reason: true,
        };
      });
    }


    if (appointmentFields.phone.trim() === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (!appointmentFields.phone.length > 12) {
      setAppointmentFieldsErrors((prevObj) => {
        return {
          ...prevObj,
          phone: true,
        };
      });
    }
    if (appointmentFields.insurance_type === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          insurance_type: true,
        };
      });
    }
    if (
      appointmentFields.insurance_type === "provider" &&
      appointmentFields.insurance_type_text.trim() === ""
    ) {

      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          insurance_type_text: true,
        };
      });
    }

    if (
      appointmentFields.insurance_type === "provider" &&
      appointmentFields.insurance_type_text.length > 50
    ) {

      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          insurance_type_text: true,
        };
      });
    }

    if (appointmentFields.selectedDate === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          selectedDate: true,
        };
      });
    }

    if (appointmentFields.slot === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          slot: true,
        };
      });
    }

    if (formValidated) {
      let formdata = new FormData();
      formdata.append("reason", appointmentFields.reason);
      formdata.append("doctor_type", appointmentFields.request_for);

      formdata.append("insurance_type", appointmentFields.insurance_type);
      formdata.append(
        "insurance_provider",
        appointmentFields.insurance_type_text
      );
      formdata.append("phone", "+" + appointmentFields.phone);
      formdata.append(
        "appointment_time",
        moment(
          new Date(
            moment(appointmentFields.selectedDate).format("YYYY-MM-DD") +
            " " +
            appointmentFields.slot
          )
        ).format("YYYY-MM-DDTHH:mm")
      );

      if (memberSelected && !isNaN(memberSelected)) {
        formdata.append("appointment_for", "someone");
        formdata.append("member", parseInt(memberSelected));
      } else {
        formdata.append("appointment_for", "me");
      }

      formdata.append("reports", appointmentFields.report_files);

      if (appointmentFields.insurance_type === "self") {
        formdata.delete("insurance_provider");

      }

      if (appointmentFields.appointment_for === "me") {
        formdata.delete("appointed_user");

      }

      setIsLoader(true);
      openAiService
        .book_appointment(formdata)
        .then((res) => {
          setShowAppointmentModal(false);
          setConsine(false);
          setIsCaptchaValue(false);
          setIsLoader(false);
          setIsChecked(false);
          setAllowTreatment(false);
          setAllowRisk(false);
          setInfoAccurate(false);
          setAllowHealthData(false);

          Swal.fire(
            {
              title: "Success",
              text: "Thank you for submitting your request. We are reviewing it and will respond soon!",
              type: "success",
              allowOutsideClick: false,
            }
          ).then((result) => {
            setAppointmentFields({
              reason: "",
              request_for: "",
              insurance_type: "self",
              insurance_type_text: "",
              appointment_for: "me",
              patient_gender: "",
              patient_name: "",
              patient_age: "",
              phone: "",
              report_files: [],
            });
            if (result.isConfirmed) {
              window.location.href = "/my_appointment";
            }
          });
        })
        .catch((err) => {
          setIsLoader(false);
          Swal.fire("Error", err?.response?.data?.error, "error");
        });
    }
  };

  function getRecommendedSlots() {
    setIsSeemar(true);
    openAiService
      .getRecommendedSlots(openaiId)
      .then((res) => {
        setIsSeemar(false);
        setRecommendedSlots(res.data.data);
        setIsExtra(true);
        setReason(res.data?.user_problem);
      })
      .catch((err) => console.log(err));
  }

  const checkFile = (allfile) => {

    for (const key of Object.keys(allfile.files)) {
      let file = allfile.files[key];
      if (file.type === "application/pdf") {
        return { status: true, message: "" };
      } else {
        return { status: false, message: "Upload a valid PDF file." };
      }
    }
  };

  window.allowModal = (value) => {
    setIsSeemar(value);
    setKeepScrollingToBottom(true);
    setElementHeight(element.scrollHeight);

    if (isUserDobExists != null) {
      getRecommendedSlots();
    } else {
      Swal.fire(
        "Error",
        "To access personalized consultation, kindly ensure that you complete your profile first.",
        "error"
      ).then(() => {
        navigate("/create_profile");

      });
    }
  };



  const handleQuerySubmit = (e) => {
    try {
      e.preventDefault();
    } catch { }
    if (showPdfPrompt) {
      if (pdfFile) {
        comparePdf(pdfFile);
      } else {
        Swal.fire("Warning", "Please select pdf file!", "warning");
      }
    } else if (showComparePdfPrompt) {
      if (pdfFile0 && pdfFile1) {
        comparePdf(pdfFile0, pdfFile1);
      } else if (!pdfFile0 || !pdfFile1) {
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
        Swal.fire(
          "Warning",
          "Please select both PDF files to compare",
          "warning"
        );
      }
    } else {
      if (queryString.trim() == "") {
        Swal.fire("Warning", "Enter some query to ask!", "warning");
      } else {
        askQuestion(queryString);
      }
    }
  };

  const startNewChat = () => {
    setOpenaiId();
    setChat([]);
    setIsBookmarked(0);
    setRecommendedSlots([]);
    setIsExtra(false);
  };



  const setCalenderData = (res) => {

    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        selectedDate: new Date(res.date),
      };
    });
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        slot: res.time,
      };
    });

    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        phone: phoneNo,
      };
    });

    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        reason: reason,
      };
    });

    getAvailableSlots(moment(new Date(res.date)).format("YYYY-MM-DD"));

    setShowAppointmentModal(true);
  };

  function setExtraCalenderData() {
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        phone: phoneNo,
      };
    });
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        reason: reason,
      };
    });

    let first_availble_recomded_slot = recommendedSlots[0];
    if (first_availble_recomded_slot) {
      getAvailableSlots(
        moment(new Date(first_availble_recomded_slot.date)).format("YYYY-MM-DD")
      );
      setAppointmentFields((prevObj) => {
        return {
          ...prevObj,
          selectedDate: new Date(first_availble_recomded_slot.date),
        };
      });

      setAppointmentFields((prevObj) => {
        return {
          ...prevObj,
          slot: first_availble_recomded_slot.time,
        };
      });
    } else {
      getAvailableSlots(moment(new Date()).format("YYYY-MM-DD"));
    }

    setShowAppointmentModal(true);
  }


  const bookmark = () => {
    if (openaiId) {
      const data = {
        thread: openaiId,
      };
      openAiService
        .bookmarkHistory(data)
        .then((res) => {
          setIsBookmarked(isBookmarked == 0 ? 1 : 0);
          getHistory();
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.clear();
            globalDataRef.current.setSignUpModal(true);
            navigate("/");
          }
        });
    }
  };


  function replaceLastSimilarOccurrence(
    inputString,
    searchArray,
    replacementString
  ) {
    let finalString = inputString;

    for (const searchString of searchArray) {
      const lowerCaseInput = finalString.toLowerCase();
      const lowerCaseSearchString = searchString.toLowerCase();
      let index = lowerCaseInput.lastIndexOf(lowerCaseSearchString);

      while (index !== -1) {
        const before = finalString.substring(0, index);
        const after = finalString.substring(index + searchString.length);

        const isWholeWord =
          (index === 0 || !isAlphaNumeric(finalString[index - 1])) &&
          (!isAlphaNumeric(finalString[index + searchString.length]) ||
            index + searchString.length === finalString.length);

        if (isWholeWord) {
          finalString = `${before}${replacementString}${after}`;
          break;
        } else {
          index = lowerCaseInput.lastIndexOf(lowerCaseSearchString, index - 1);
        }
      }
    }

    return finalString;
  }

  function isAlphaNumeric(char) {
    return /[a-zA-Z0-9]/.test(char);
  }

  const handleListing = () => {
    setIsListening(true);
    var timer = 60;

    SpeechRecognition.startListening({
      continuous: true,
    });

    interval.current = setInterval(() => {
      timer -= 1;
      setcountHtml(timer + " seconds remaining");
      if (timer < 1) {

        stopHandle();
      }
    }, 1000);

  };
  const stopHandle = () => {
    setIsListening(false);
    resetCounter();
    SpeechRecognition.stopListening();
    setQueryString(document.getElementById("input_focused").value);
  };

  const handleReset = () => {
    setQueryString("");
    resetTranscript();
  };

  function resetCounter() {
    setcountHtml("");
    clearInterval(interval.current);
  }

  const deleteHistory = (id) => {
    if (history.length == 1) {
      setHistory([]);
      setBookmarks([]);
    }
    openAiService
      .delete_history(id)
      .then((res) => {
        getHistory();
        window.location.reload();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  };
  const remove_bookmark = (id) => {
    openAiService
      .remove_bookmark(id)
      .then((res) => {
        Swal.fire("Success", "Bookmark removed", "succes");
        getHistory();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  };

  return (
    <>
      <section className="inner-chatbot" id="inner-chatbot-id">
        <Container className="position-unset">
          {isLoader && <Loading />}
          <Row>
            <Col lg={3} md={5}>
              <div
                className={`left-chat-boxes y ${window?.innerWidth < 1200 && onMobileClick
                    ? "mobile-view-bookmark"
                    : ""
                  }`}
              >
                <div className="overflow">
                  <div className="chat-history-header">
                    <span>{`Hello${localStorage.getItem("user")
                        ? `, ${capitalizeFirstLetter(
                          JSON.parse(localStorage.getItem("user"))?.name
                        )}`
                        : ""
                      } 👋`}</span>
                  </div>
                  <div className="add-chat-btn">
                    <Button
                      type="button"
                      disabled={
                        showLoader ||
                        showChatResponse ||
                        chatTriggered ||
                        isListening
                      }
                      variant="unset"
                      onClick={() => {
                        setMemberName("");
                        setMemberSelected();
                        setIsActive("me");
                        startNewChat();
                      }}
                    >
                      + New chat
                    </Button>
                  </div>
                  <h5 className="text-center">Bookmarks:</h5>
                  <div className="chat-bookmark-area chat-history-left">
                    {bookmarks.length ? (
                      bookmarks.map((historyObj, index) => {
                        return (


                          <>
                            <span
                              key={historyObj.id}
                              className={
                                historyObj.id == openaiId
                                  ? "history-obj-active"
                                  : ""
                              }
                              onClick={() => {
                                setRecommendedSlots([]);
                                setIsExtra(false);
                                getChat(historyObj.id);
                              }}
                            >
                              {`${historyObj.title.substring(0, 27).trim()}`}
                            </span>
                          </>
                        );
                      })
                    ) : !historyLoading ? (
                      <p>{`No bookmarks yet :(`} </p>
                    ) : (
                      <RotatingLines width="20" strokeColor="#111" />
                    )}
                  </div>

                  <h5 className="text-center mt-2">History:</h5>
                  <div className="chat-history-area chat-history-left">
                    {history.length && !historyLoading ? (
                      history.map((historyObj, index) => {
                        return (
                          <>
                            <span
                              key={historyObj.openai_id}
                              className={
                                historyObj.openai_id == openaiId
                                  ? "history-obj-active"
                                  : ""
                              }
                              onClick={() => {
                                setAppointmentFields((prevObj) => {
                                  return {
                                    ...prevObj,
                                    reason: reason,
                                  };
                                });
                                setRecommendedSlots([]);
                                setIsExtra(false);
                                getChat(historyObj.id);
                              }}
                            >
                              {historyObj.title.length > 27
                                ? `${historyObj.title
                                  .substring(0, 27)
                                  .trim()}...`
                                : historyObj.title.substring(0, 27).trim()}
                              {historyObj.pdf_file ? (
                                <a
                                  href={baseUrl + "/" + historyObj.pdf_file}
                                  target="_blank"
                                  title="Download Agreement"
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              ) : null}

                              <i
                                className="fa fa-trash ms-2"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Warning",
                                    text: "Are you sure you want to delete this chat history?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Delete",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteHistory(historyObj.id);
                                    }
                                  });
                                }}
                              ></i>
                            </span>
                          </>
                        );
                      })
                    ) : !historyLoading ? (
                      <>
                        <p>{`No history yet :(`} </p>
                      </>
                    ) : (
                      <RotatingLines width="20" strokeColor="#111" />
                    )}
                  </div>
                </div>
              </div>
              <div>{/* <a href="javascript:;" >My Appointments</a> */}</div>
            </Col>
            <Col lg={9} md={7}>
              <div className="mid-chat-box">
                <div className="chatbox-header">
                  <div className="inner-chatbot-main">
                    <h3>Skyler ✍️</h3>
                  </div>
                  <span>
                    {JSON.parse(localStorage.getItem("user"))
                      ?.subscription_plan != "null" &&
                      openaiId &&
                      isBookmarked === 1 &&
                      bookmarkDisabled ? (
                      <img
                        onClick={bookmark}
                        className={
                          showLoader || showChatResponse || chatTriggered
                            ? "disableBtn bookmark-img"
                            : "bookmark-img"
                        }
                        src={require("../../assests/images/bookmark.png")}
                        alt="bookmark"
                      ></img>
                    ) :
                      openaiId && isBookmarked === 0 && bookmarkDisabled ? (
                        <img
                          onClick={bookmark}
                          className={
                            showLoader || showChatResponse || chatTriggered
                              ? "disableBtn bookmark-img"
                              : "bookmark-img"
                          }
                          src={require("../../assests/images/bookmark-white.png")}
                          alt="bookmark"
                        ></img>
                      ) : null}
                  </span>
                </div>
                <div id="chatbox-body-id" className="chatbox-body">
                  {openaiId ? null : (
                    <>
                      <div className="left-chat-box">
                        <p className="prompt-heading">
                          Please choose a question below, or feel free to type
                          your own inquiry
                        </p>
                      </div>
                      {sampleQuestions.map((que, index) => {
                        return (
                          <div key={index} className="left-chat-box ">
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                askQuestion(que[1]);

                              }}
                            >
                              {<img src={que[0]}></img>}
                              {que[1]}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}

                  {chat.length > 0
                    ? chat.map((chatObj, index) => {
                      var replaces = chatObj.text;
                      if (chatObj.type === "image" && chatObj.text != null) {
                        return (
                          <div key={index} className="right-chat-box">
                            <img src={chatObj.text} alt="image" />
                          </div>
                        );
                      } else if (chatObj.type === "answer") {
                        if (
                          chatObj.text.includes("Book an appointment") ||
                          chatObj.text.includes("booking an appointment") ||
                          chatObj.text.includes("book an appointment") ||
                          chatObj.text.includes("Booking an appointment")
                        ) {
                          const originalString = chatObj.text;
                          const searchArray = [
                            "Book an appointment",
                            "booking an appointment",
                            "book an appointment",
                            "Booking an appointment",
                          ];
                          const replacementString = `<a href="javascript:;" id="set-appointment" onClick={allowModal(true)} style={{cursor:"pointer"}} >Book an Appointment</a>`;

                          replaces = replaceLastSimilarOccurrence(
                            originalString,
                            searchArray,
                            replacementString
                          );
                        }
                        return (
                          <div key={index} className="left-chat-box">
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: replaces }}
                              ></div>{" "}
                            </p>
                            <br></br>{" "}
                          </div>
                        );
                      } else {
                        return (
                          <div key={index} className="right-chat-box">
                            <p>{chatObj.text}</p>
                          </div>
                        );
                      }
                    })
                    : null}
                  {!isSeemar &&
                    recommendedSlots &&
                    recommendedSlots.length > 0 ? (
                    <p className="select-slot">
                      <b>Select your preferred slot:</b>
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="clock-main">
                    {isSeemar && (
                      <>
                        {[1, 2, 3, 4].map(() => (
                          <SkeletonTheme>
                            <span
                              style={{ cursor: "pointer" }}
                              className="tags main"
                            >
                              <h6>
                                <Skeleton count={1} height={40} width={45} />
                              </h6>
                              <p></p>
                            </span>
                          </SkeletonTheme>
                        ))}
                        <span>
                          <h6>
                            <Skeleton
                              style={{ background: "#7269ef" }}
                              count={1}
                              height={25}
                              width={90}
                            />
                          </h6>
                        </span>
                        {/* </SkeletonTheme> */}
                      </>
                    )}

                    {!isSeemar &&
                      recommendedSlots &&
                      recommendedSlots.length > 0
                      ? recommendedSlots.map((res) => {
                        return (
                          <span
                            style={{ cursor: "pointer" }}
                            className="tags main"
                            onClick={() => {
                              setCalenderData(res);
                            }}
                          >
                            <h6>
                              {CommonFunction.returnFormattedDay(res.date)}
                            </h6>
                            <p>
                              <i className="fa fa-clock-o" aria-hidden="true"></i>
                              {removeSecondFromString(res.time)}
                            </p>
                          </span>
                        );
                      })
                      : ""}
                    {!isSeemar && isExtra && (
                      <span
                        style={{ cursor: "pointer" }}
                        className="view-more"
                        onClick={() => {
                          setExtraCalenderData();
                        }}
                      >
                        <h6>{"Pick another slot"}</h6>
                      </span>
                    )}
                  </div>

                  {showLoader && (
                    <div className="left-chat-box">
                      <div className="typing-loader"></div>
                    </div>
                  )}
                  {showChatResponse && (
                    <div className="left-chat-box">
                      <p id="chat-response-id"></p>
                      <br></br>{" "}
                    </div>
                  )}
                </div>

                <div className="chatbox-footer">
                  {uploadedImage && (
                    <div className="upload-image-outer">
                      <div className="uploaded-image-area">
                        <img src={uploadedImage} alt="Uploaded preview" />
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          onClick={() => setUploadedImage(null)}
                        ></i>
                      </div>
                    </div>

                  )}
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    className="prompt"
                    disabled={
                      showLoader ||
                      showChatResponse ||
                      chatTriggered ||
                      isListening
                    }
                  >
                    Prompt Library +
                  </Button>
                  <form onSubmit={(e) => handleQuerySubmit(e)}>
                    <div className="chat-footer-left">

                      <textarea
                        id="input_focused"
                        className="noSelect"
                        ref={input_focused}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            if (
                              showLoader ||
                              showChatResponse ||
                              chatTriggered ||
                              isListening
                            ) {
                              return false;
                            } else {
                              if (e.keyCode === 13 && !e.shiftKey) {
                                handleQuerySubmit(e);
                              }
                            }
                          }
                        }}
                        placeholder="Ask something about your insurance!"
                        value={queryString}
                        onChange={(e) => {
                          if (isListening) {
                            return false;
                          } else {
                            setQueryString(e.target.value);
                          }
                        }}
                      ></textarea>
                    </div>


                    <div className="chat-module">
                      <div className="send-record-btn">
                        <button
                          type="submit"
                          style={{ cursor: "pointer" }}
                          disabled={
                            showLoader ||
                            showChatResponse ||
                            chatTriggered ||
                            queryString.trim().length === 0 ||
                            isListening
                          }
                          className={
                            showLoader ||
                              showChatResponse ||
                              chatTriggered ||
                              queryString.trim().length === 0 ||
                              isListening ||
                              (queryString !== "" && isListening)
                              ? "send-btn-disabled submit submit-light"
                              : "submit submit-light"
                          }
                        >
                          <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>

                        {navigator.userAgent.includes("Chrome") &&
                          !window.navigator?.brave?.isBrave ? (
                          <div className="microphone-section-area">
                            {!isListening && (
                              <div
                                className={
                                  showLoader ||
                                    showChatResponse ||
                                    chatTriggered ||
                                    isListening
                                    ? "disableBtn microphone-icon-container submit"
                                    : "microphone-icon-container submit"
                                }

                                style={{ cursor: "pointer" }}

                                onClick={
                                  showLoader ||
                                    showChatResponse ||
                                    chatTriggered ||
                                    isListening
                                    ? () => {
                                      return false;
                                    }
                                    : () => {
                                      handleListing();
                                      document
                                        .getElementById("input_focused")
                                        .focus();
                                    }
                                }
                              >
                                <i
                                  className="fa fa-microphone microphone-icon"
                                  aria-hidden="true"
                                ></i>

                                {/* <img src={microPhoneIcon} className="microphone-icon" /> */}
                              </div>
                            )}
                            {isListening && (
                              <div
                                className="microphone-icon-container submit"
                                onClick={stopHandle}
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className="fa fa-stop-circle"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            )}
                            <div>
                              <p id="counter">{counterHtml}</p>
                            </div>
                            <div className="microphone-status">
                              {isListening ? (
                                <i className="fa-light fa-waveform-lines"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {navigator.userAgent.includes("Chrome") &&
                        !window.navigator?.brave?.isBrave
                        ? queryString && (
                          <div
                            className="microphone-icon-container submit"
                            onClick={handleReset}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                          </div>
                        )
                        : ""}
                    </div>
                  </form>
                  <p className="sub-para">
                    {Project_Title} may produce inaccurate information about
                    people, places, or facts.
                  </p>
                </div>
                {/* } */}
              </div>
              <div className="chat-animated-button">
                <button
                  onClick={() => {
                    setOnMobileClick(!onMobileClick);
                    ChangeText(buttonText);
                  }}
                  className="animated-btn"
                >
                  <img src={buttonText} alt="img" className="me-1" />
                </button>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </section>
      {/* modal */}

      <Modal show={showAdd} onHide={handleCloseAdd} centered>
        <Modal.Header closeButton>
          <Modal.Title>Family Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="family-boxes">
            {members.length > 0 ? (
              members.map((res, index) => {
                console.log("memememberer111", memberSelected, res.id);
                return (
                  <div className="family-inner-box rounded-family-img">
                    <div>
                      <img
                        src={
                          res.image
                            ? baseUrl + res.image
                            : require("../../assests/images/family-img.png")
                        }
                        alt="img"
                        className="me-1"
                      />
                      <h5>{res.name}</h5>
                    </div>
                    <input
                      type="checkbox"
                      className="rounded-checkbox"
                      id={"member_" + i}
                      name="member_check"
                      disabled={memberSelected == res.id}
                      value={res.id}
                      onChange={(e) => {
                        changeData("other", e, res.name);
                      }}
                      checked={memberSelected == res.id ? true : ""}
                    />
                  </div>
                );
              })
            ) : (
              <div className="single-family-popup">
                <img
                  className=""
                  src={require("../../assests/images/Family-add.svg").default}
                  alt="img"
                />
                <Button
                  onClick={() => {
                    handleCloseAdd();
                    setMemberModal(true);
                  }}
                >
                  Add Member
                </Button>
                {/* <Button onClick={() => navigate("/my_profile")}>Add Member</Button> */}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Prompt Library</h5>
            <p className="paragraph-txt">
              Choose the prompt that best fits your preference. Upon clicking
              the question, it will be promptly transmitted to the Skyler.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="prompt-box right-chat-box-dropdown dropdown-spacing redesign-prompt-library">
            <Accordion defaultActiveKey="">
              {prompts.map((que, index) => {
                return (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{que[0]}</Accordion.Header>

                    {que[1].map((quee, index) => {
                      return (
                        <Accordion.Body>
                          <p
                            onClick={(e) => {
                              let que = e.target.innerHTML;
                              askQuestion(que);

                              setShow(false);
                            }}
                          >
                            {quee}{" "}
                          </p>
                        </Accordion.Body>
                      );
                    })}
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={upGradePlanPopupModal}
        onHide={() => {
          setUpgradePlanPopupModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ cursor: "pointer" }}>
            <div className="upgrade-plan">
              <img
                src={require("../../assests/images/upgrade.png")}
                alt="img"
              />
              <p className="premium-plans">
                For access to this feature, upgrade to Premium.
              </p>
              <div
                className="upgrade-to-pro-style w-50"
                onClick={() => {
                  globalDataRef.current.setScrollToPricing(true);
                  globalDataRef.current.setShowPopupModal(false);
                  upgradeToPremium();
                }}
              >
                {" "}
                Upgrate to Premium
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={globalDataRef.current.showPopupModal}
        onHide={() => {
          globalDataRef.current.setShowPopupModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes right-chat-spacing">
            <div className="left-chat-box">
              <div className="chat-history-header">
                <span>Other Features 🦾</span>
              </div>
              <div className="right-chat-box-dropdown chat-agreement-area">
                <Dropdown autoClose={false}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Summarize Report
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    Upload Document
                    <div>
                      <Form.Group
                        controlId="formFile"
                        className="uplaod-document-area"
                      >
                        <Form.Label>
                          Click to browse or drag
                          <br /> and drop PDF file
                        </Form.Label>
                        <Form.Control
                          type="file"
                          multiple={false}
                          accept="application/pdf"
                          id="upload-pdf-1"
                          style={{ minWidth: "100%" }}
                          onChange={(e) => {
                            let check = checkFile(e.target);
                            if (check.status) {
                              setShowPdfPrompt(true);
                              for (const key of Object.keys(e.target.files)) {
                                setPdfFile((pdfFile) => [
                                  ...pdfFile,
                                  e.target.files[key],
                                ]);
                              }


                            } else {
                              Swal.fire("Warning", check.message, "warning");
                            }
                          }}
                        />
                      </Form.Group>
                      {pdfFile &&
                        pdfFile.length > 0 &&
                        pdfFile.map((filePdf) => {


                          return (
                            <p className="sub-para">
                              {filePdf.name.length > 20
                                ? `${filePdf.name.substring(20, 0).trim()}...`
                                : filePdf.name}
                              {","}
                            </p>
                          );
                        })}
                      {/* {pdfFile ? pdfFile.name.length > 20 ? <p className="sub-para">{`${pdfFile.name.substring(20, 0).trim()}...`}</p> : <p className="sub-para">{pdfFile.name}</p> : null} */}
                      <br />

                      <button
                        variant="unset"
                        disabled={showChatResponse || !pdfFile}
                        className={
                          showChatResponse || !pdfFile
                            ? "btn-disabled"
                            : "try-ti-buton"
                        }
                        onClick={() => {

                          setShowPdfPrompt(true);
                          globalDataRef.current.setShowPopupModal(false);
                          handleQuerySubmit();



                        }}
                      >
                        Go
                      </button>
                    </div>
                    {/* </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-area-box appointment-modal"
        backdrop="static"
        show={showAppointmentModal}
        onHide={() => {
          setShowAppointmentModal(false);
          setConsine(false);
          setIsCaptchaValue(false);
          setAppointmentFields({
            reason: "",
            insurance_type: "self",
            insurance_type_text: "",
            appointment_for: "me",
            patient_gender: "",
            patient_name: "",
            patient_age: "",
            phone: "",
            selectedDate: currentDateToSelect,
            request_for: "",
            report_files: [],
            slot: "",
          });
          setAppointmentFieldsErrors({
            reason: false,
            insurance_type: false,
            insurance_type_text: false,
            appointment_for: false,
            patient_gender: false,
            patient_name: false,
            patient_age: false,
            phone: false,
            selectedDate: false,
            request_for: false,
            report_files: false,
            slot: false,
          });
          setAllowTreatment(false);
          setAllowRisk(false);
          setInfoAccurate(false);
          setAllowHealthData(false);
          setIsChecked(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center p-0">
          <div className="reason-boxes">
            <div className="left-chat-box mt-0">
              <div className="">
                <div>
                  <Form
                    id="contanctForm"
                    onSubmit={handleContactUsSubmit}
                    encType="multipart/form-data"
                  >
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Reason For Visit* </Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        maxLength={500}
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter reason here"
                        className={
                          appointmentFieldsErrors.reason ? "border-red" : ""
                        }
                        value={appointmentFields.reason}

                        onChange={(e) => {
                          setAppointmentFields((prevObj) => {
                            return {
                              ...prevObj,
                              reason: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                reason: true,
                              };
                            });
                          } else if (e.target.value.length > 500) {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                reason: true,
                              };
                            });
                          } else {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                reason: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>

                    <div className="row">
                      <aside className="col-md-6">
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Phone Number*</Form.Label>
                          <PhoneInput
                            country={
                              COUNTRY_CODE
                                ? COUNTRY_CODE.toLocaleLowerCase()
                                : "us"
                            }
                            className={
                              appointmentFieldsErrors.phone
                                ? "border-red phone-input"
                                : "phone-input"
                            }
                            placeholder="Enter phone number"
                            value={appointmentFields.phone}
                            onChange={(e) => {
                              setAppointmentFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  phone: e,
                                };
                              });
                              if (e.trim() === "") {
                                setAppointmentFieldsErrors((prevObj) => {
                                  return {
                                    ...prevObj,
                                    phone: true,
                                  };
                                });
                              } else if (!e.length > 12) {
                                setAppointmentFieldsErrors((prevObj) => {
                                  return {
                                    ...prevObj,
                                    phone: true,
                                  };
                                });
                              } else {
                                setAppointmentFieldsErrors((prevObj) => {
                                  return {
                                    ...prevObj,
                                    phone: false,
                                  };
                                });
                              }
                            }}
                          />
                        </Form.Group>
                      </aside>

                      <aside className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Upload Medical Report</Form.Label>
                          <Form.Control
                            type="file"
                            multiple={false}
                            accept="application/pdf"
                            id="upload-pdf-2"
                            style={{ minWidth: "100%" }}
                            className={
                              appointmentFieldsErrors.report_files
                                ? "border-red"
                                : ""
                            }
                            onChange={(e) => {
                              let check = checkFile(e.target);
                              if (check.status) {
                                setShowPdfPrompt(true);
                                setAppointmentFields((prevObj) => {
                                  return {
                                    ...prevObj,
                                    report_files: e.target.files[0],
                                  };
                                });
                              } else {
                                document.getElementById("upload-pdf-2").value =
                                  null;
                                setAppointmentFields({ report_files: "" });
                                Swal.fire("Warning", check.message, "warning");
                              }
                            }}
                          />
                        </Form.Group>
                        {files.length > 0
                          ? files.map((file) => (
                            <p>
                              {file.name}
                              <i
                                className="fa fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </p>
                          ))
                          : ""}
                      </aside>
                    </div>
                    <Form.Group
                      controlId="formFile"
                      className=" insurance-type-area mb-3"
                    >
                      <Form.Label>Insurance Type*</Form.Label>
                      <input
                        type="radio"
                        value="provider"
                        id="insurance_type"
                        className={
                          appointmentFieldsErrors.insurance_type
                            ? "border-red"
                            : ""
                        }
                        checked={
                          appointmentFields.insurance_type === "provider"
                        }
                        onChange={handleOptionChange}
                      />
                      <p className="text-insu">Insurance Provider</p>
                      <input
                        className="ms-4"
                        type="radio"
                        value="self"
                        id="insurance_type"
                        checked={appointmentFields.insurance_type === "self"}
                        onChange={handleOptionChange}
                      />
                      <p className="text-insu">Self</p>

                      {appointmentFields.insurance_type === "provider" ? (
                        <div>
                          <Form.Group
                            controlId="formFile"
                            className="insurance-type-field "
                          >
                            <input
                              type="text"
                              id="insurance_type_text"
                              placeholder="Insurance Type"
                              maxLength={50}
                              value={appointmentFields?.insurance_type_text}
                              className={
                                appointmentFieldsErrors.insurance_type_text
                                  ? "border-red"
                                  : ""
                              }
                              onChange={(e) => {
                                setAppointmentFields((prevObj) => {
                                  return {
                                    ...prevObj,
                                    insurance_type_text: e.target.value,
                                  };
                                });
                                if (e.target.value.length > 50) {
                                  setAppointmentFieldsErrors((prevObj) => {
                                    return {
                                      ...prevObj,
                                      insurance_type_text: true,
                                    };
                                  });
                                } else if (e.target.value.trim() === "") {
                                  setAppointmentFieldsErrors((prevObj) => {
                                    return {
                                      ...prevObj,
                                      insurance_type_text: true,
                                    };
                                  });
                                } else {
                                  setAppointmentFieldsErrors((prevObj) => {
                                    return {
                                      ...prevObj,
                                      insurance_type_text: false,
                                    };
                                  });
                                }
                              }}
                            />
                          </Form.Group>
                          <div className="text-left my-2">
                            {(providers[0] || providers[1]) && (
                              <p className="suggestion-tag">
                                Some Suggested Insurance*
                              </p>
                            )}
                            <div className="tags-box">
                              {providers &&
                                providers.map((res, i) => {
                                  if (res !== null) {
                                    return (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className={
                                          i === providerId
                                            ? "active tags"
                                            : "tags"
                                        }
                                        onClick={(res) => {
                                          setProviderId(i);
                                          setAppointmentFields((prevObj) => {
                                            return {
                                              ...prevObj,
                                              insurance_type_text:
                                                res.target.innerText,
                                            };
                                          });
                                        }}
                                      >
                                        {res}
                                      </span>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Appointment Date*</Form.Label>
                      <div
                        className={
                          appointmentFieldsErrors.selectedDate
                            ? "border-red position-relative"
                            : appointmentFieldsErrors.slot
                              ? "border-red position-relative"
                              : "position-relative"
                        }
                      >
                        <DatePicker
                          selected={appointmentFields.selectedDate}
                          onChange={handleDateChange}
                          filterDate={isDayEnabled}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          minDate={new Date()}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </div>
                      <br></br>
                      <div className="tags-box-main">
                        {availableSlots && availableSlots.length > 0
                          ? availableSlots.map((res) => {
                            return res.available ? (
                              <span
                                style={{ cursor: "pointer" }}
                                className={
                                  appointmentFields.slot === res.time
                                    ? "tags active"
                                    : "tags"
                                }
                                onClick={(e) => {
                                  setAppointmentFieldsErrors((obj) => {
                                    return {
                                      ...obj,
                                      slot: false,
                                    };
                                  });
                                  setAppointmentFields((prevObj) => {
                                    return {
                                      ...prevObj,
                                      slot: res.time,
                                    };
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                ></i>
                                {removeSecondFromString(res.time)}
                              </span>
                            ) : (
                              <span
                                style={{ cursor: "pointer" }}
                                className={"tag-disables tags"}
                              >
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                ></i>
                                {removeSecondFromString(res.time)}
                              </span>
                            );
                          })
                          : ""}
                      </div>
                    </Form.Group>

                    <Form.Group className="upper-appointment">
                      <Form.Check
                        inline
                        name="consent"
                        type="checkbox"
                        value={isChecked}
                        onChange={handleChange}
                        checked={isChecked}
                        id={`inline-checkbox-1`}
                      />
                      <span
                        onClick={() => setConsentShow(true)}
                        className="appointment-text"
                      >
                        To book an appointment with us, we kindly request that
                        you provide your consent by{" "}
                        <a className="purple-txt">
                          {" "}
                          agreeing to our terms and conditions{" "}
                        </a>
                      </span>
                    </Form.Group>
                    <br />

                    <Form.Group
                      controlId="formFile"
                      className="mb-2 captcha-size"
                    >
                      <ReCAPTCHA
                        sitekey="6LeKLXAoAAAAAH20G0gfS6AaDgK6zhPjbcNBDeZW"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <br />

                    <button
                      className="try-ti-buton"
                      disabled={
                        isCaptchaValue &&
                          allowTreatment &&
                          allowRisk &&
                          infoAccurate &&
                          allowHealthData &&
                          isChecked
                          ? false
                          : true
                      }
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="success-box appointment-modal"
        show={consentShow}
        onHide={hideConsent}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div key={`inline-checkbox`} className="mb-2 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() => setAllowTreatment(allowTreatment ? false : true)}
              checked={allowTreatment}
              id={`inline-checkbox-1`}
            />
            <Form.Label>
              I authorize [Skyler] to perform my treatment or
              necessary procedure to me/ or my (for parent/Guardian) dependent.
            </Form.Label>
          </div>
          <div key={`inline-checkbox`} className="mb-2 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() => setAllowRisk(allowRisk ? false : true)}
              id={`inline-checkbox-1`}
              checked={allowRisk}
            />
            <Form.Label>
              I understand the risk and complications if I do not follow the
              instructions given to me after the procedure which involves
              post-treatment and follow-ups.
            </Form.Label>
          </div>
          <div key={`inline-checkbox`} className="mb-2 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() => setInfoAccurate(infoAccurate ? false : true)}
              id={`inline-checkbox-1`}
              checked={infoAccurate}
            />
            <Form.Label>
              I acknowledge that all information I provided in this form is true
              and accurate.
            </Form.Label>
          </div>
          <div key={`inline-checkbox`} className="mb-3 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() =>
                setAllowHealthData(allowHealthData ? false : true)
              }
              id={`inline-checkbox-1`}
              checked={allowHealthData}
            />
            <Form.Label>
              I authorize [Skyler] to access my insurance related data.
            </Form.Label>
          </div>
          <div className="text-center">
            <button
              className="try-ti-buton"
              disabled={
                allowTreatment && allowRisk && infoAccurate && allowHealthData
                  ? false
                  : true
              }
              onClick={() => setConsentShow(false)}
            >
              Agree
            </button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Chat;
