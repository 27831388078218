
import { Button, Col, Container, Row, Form, Modal } from "react-bootstrap";
const SuccessPopup = ({ show, handleClose,message1,message2,redirectlink  }) => {
    return (
        <>
         <Modal className="success-box" show={show} onHide={handleClose}  centered>
                <Modal.Header>
                    <Modal.Title>
                        {/* <img src={require("../../assets/images/chemark.png")} alt="img" /> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{message1}</h5>
                    <p>{message2}</p>
                </Modal.Body>
                <Modal.Footer>
                   
                    <Button variant="primary" onClick={()=>handleClose(redirectlink)}>
                     Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
            );
};
export default SuccessPopup; 