import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();
    return(
        <>
        <div className="error-area">
            <div className="inner-content">
            <h1>404</h1>

            <h3>Looks like the page you're looking for doesn't exist.</h3>
            <span className="try-ti-buton" onClick={()=>navigate("/")}>Back to home</span>
        </div>
        </div>
        </>
    )
}

export default ErrorPage;