import "./App.css";
import Routing from "./Components/common/Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "./css/style_availability.css";
function App() {
  return <Routing />;
}

export default App;
