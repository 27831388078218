export const CommonFunction = {

    ChangeText,
    returnFormattedDay
    }
    
    function ChangeText(text,setButtonText){
        if(text == "Assistant"){
            setButtonText("Close")
         }else{
          setButtonText("Assistant")
         }  
    }
    
    function returnFormattedDay(date_str) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1); // Add 1 to today's date and set it to tomorrow
    
        if (today.toDateString() === new Date(date_str).toDateString()) {
          return "Today";
        } else if (tomorrow.toDateString() === new Date(date_str).toDateString()) {
          return "Tomorrow";
        } else {
          const date_get = new Date(date_str);
          const day = date_get.getDay();
          const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];
          return dayNames[day];
        }
      }