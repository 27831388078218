import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import Chat from "../pages/Chat";
import DocumentChat from "../pages/DocumentChat";
import Home from "../pages/Home";
import ErrorPage from "../pages/ErrorPage";
import MyProfile from "../pages/MyProfile";
import ProfilePage from "../pages/ProfilePage";
import Login from "../Login";
import ComingSoon from "../pages/comingSoon";
import Cancer from "../pages/cancerAdvisory";
import { ChatWidget } from "../ChatWidget/ChatWidget";

const
  Routing = () => (
    <Router>
      <Routes>
        <Route path="/" element={<CommonLayout>{<Home />}</CommonLayout>}></Route>
        <Route
          path="/specialist/:type"
          element={<CommonLayout>{<Cancer />}</CommonLayout>}
        ></Route>
        <Route
          path="/247-virtual-assistant"
          element={<CommonLayout>{<Chat />}</CommonLayout>}
        ></Route>
        <Route
          path="/policy-insights"
          element={<CommonLayout>{<DocumentChat />}</CommonLayout>}
        ></Route>
        <Route
          path="/home"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
        <Route
          path="/create_profile"
          element={<CommonLayout>{<MyProfile />}</CommonLayout>}>
        </Route>
        <Route
          path="/my_profile"
          element={<CommonLayout>{<ProfilePage />}</CommonLayout>}>
        </Route>
        <Route path="/login"
          element={<CommonLayout>{<Login />}</CommonLayout>}
        ></Route>
        <Route
          exact="true" path="/*"
          element={<ErrorPage />}
        ></Route>
        <Route
          path="/coming-soon"
          element={<ComingSoon />}
        ></Route>
        <Route
          exact="true" path="/chat-widget"
          element={<ChatWidget />}
        />

      </Routes>
    </Router>
  );

export default Routing;
